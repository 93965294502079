export const contactText = {
  pt: {
    language: 'pt',
    title: 'Contato',
    stickerDesignResumePDF: 'Currículo inDesign PDF - PT',
    designedResumeLink:'https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:7c2dfd27-63ea-497b-bcba-4ccf11edf133',
    stickerSimpleResumePDF: 'Currículo simples PDF - PT',
    simpleResumeLink:'https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:c17e5765-e8df-4a8e-859e-48dbb0bdac28',
    mainText: `Gostou do meu trabalho? Quer me contratar?
      Criticar ou elogiar algum dos meus projetos?
      Por favor, fique à vontade para se comunicar
      comigo em qualquer umas das minhas redes sociais...`,
    facebook: 'Link do meu perfil no Facebook',
    email: 'Endereço do meu email',
    twitter: 'Link do meu perfil no Twitter',
    instagram: 'Link do meu perfil no Instagram',
    linkedin: 'Link do meu perfil no LinkedIn',
    github: 'Link do meu perfil no GitHub',
  },
  en: {
    title: 'Contact',
    mainText: `Do you like my work? Want to hire me? Criticize or compliment one of my projects?
      Please feel free to reach me through any one of my social media...`,
    stickerDesignResumePDF: 'inDesign Resume PDF - EN',
    designedResumeLink: 'https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:f8a6affb-290d-4a4c-bc94-cfa0ae188144',
    stickerSimpleResumePDF: 'Simple Resume PDF - EN',
    simpleResumeLink:'https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:31a15545-3559-4daf-b1d9-2e225f680fb4',
    facebook: 'My Facebook page link',
    email: 'My email link',
    twitter: 'My Twitter link',
    instagram: 'My Instagram link',
    linkedIn: 'My LinkedIn link',
    github: 'My GitHub page link',
  },
};
