// eslint-disable-next-line no-unused-vars
import react from 'react';
import styles from './styles.module.css';

function Frame({ rotate }) {

  return (
		<svg
      className={rotate? styles["rotatedFrame"] : styles.frameSvg}
      style={{ transform: `rotate(${rotate})` }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1288.249 1724.076"
      enableBackground="new 0 0 1288.249 1724.076"
      xmlSpace="preserve"
    >
      <g>
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M281.262,66.026c8.992-2.415,18.284-3.494,27.561-3.947
		c3.405-0.079,6.825-0.07,10.216,0.294c21.652,1.051,43.042,5.61,63.563,12.491c32.47,10.992,62.951,27.491,90.694,47.563
		c7.39,5.223,14.056,11.393,21.652,16.336c1.756,1.191,3.527,2.368,5.311,3.527c18.55-9.53,37.338-18.634,56.514-26.837
		c18.975-7.75,39.071-12.631,59.419-14.939c23.45-2.658,47.223-1.99,70.565,1.35c15.499,2.238,30.863,5.573,45.793,10.314
		c3.929,1.528,8.207,1.565,12.295,2.471c36.684,7.03,71.453,22.441,102.536,42.981c30.275,20.04,56.948,45.289,79.487,73.704
		c22.581,28.416,41.08,59.938,56.079,92.95c14.36,31.597,25.557,64.576,34.292,98.145c2.429,9.132,4.498,18.354,6.661,27.547
		c1.135-3.812,1.462-7.857,2.705-11.664c1.808-7.586,6.362-14.187,11.702-19.736c1.901-2.158,4.288-3.821,6.18-5.975
		c8.955-13.103,13.729-28.948,13.846-44.798c0.149-14.411-3.504-28.668-9.474-41.72c-2.532-5.04-4.326-10.417-7.058-15.364
		c-4.751-9.002-10.665-17.541-18.344-24.3c-3.046-2.728-6.544-4.877-9.782-7.362c-13.552-10.23-26.211-21.684-37.497-34.381
		c-10.51-11.884-19.895-24.908-26.697-39.286c-4.354-9.231-7.624-19.022-9.146-29.13c-1.49-9.74-1.299-19.746,0.724-29.397
		c1.864-9.044,5.316-17.714,9.814-25.758c-1.154,8.871,0.995,17.802,3.989,26.108c5.769,15.392,15.219,29.112,25.776,41.594
		c16.378,19.194,35.75,35.577,55.934,50.614c10.571,8.871,19.867,19.279,27.509,30.775c9.1,13.785,15.658,29.602,16.845,46.204
		c1.163,16.27,0.023,32.746-3.784,48.624c-2.92,11.959-7.283,23.707-14.084,34.031c-4.475,6.694-10.104,12.884-17.307,16.681
		c-1.588,0.902-3.527,1.233-4.802,2.621c-5.358,5.367-10.235,11.604-12.267,19.031c-1.775,6.404-3.284,12.991-3.293,19.662
		c1.682,8.282,3.307,16.579,4.816,24.898c3.466,0.271,6.853,1.102,10.216,1.948c4.242-5.979,8.955-11.618,14.051-16.887
		c4.555-4.807,9.562-9.16,14.042-14.037c15.257-16.476,28.836-34.47,41.019-53.319c13.164-20.498,24.576-42.187,33.162-65.002
		c8.969-23.889,14.733-49.189,14.986-74.783c0.224-24.786-5.022-49.815-16.443-71.896c9.207,7.208,16.35,16.737,22.184,26.79
		c13.08,22.997,18.433,49.68,18.835,75.937c0.453,30.083-5.096,59.98-13.617,88.741c-4.522,11.627-10.562,22.614-17.223,33.143
		c-12.309,19.199-27.556,36.633-45.63,50.605c-8.357,6.4-17.265,12.029-25.973,17.933c-9.702,6.573-19.652,12.795-29.13,19.699
		c0.168,3.798,2.354,7.072,2.873,10.8c0.467,3.088,0.299,6.437-1.303,9.198c-0.776,1.383-2.018,2.401-3.102,3.527
		c7.021,1.359,13.757,4.555,18.942,9.53c4.634,4.461,8.1,10.254,9.319,16.607c1.579,7.684-0.037,15.644,1.724,23.305
		c0.514,2.415,1.448,4.713,2.396,6.993c-0.248,0.196-0.747,0.589-1,0.785c-5.984-4.19-14.463-5.526-20.909-1.598
		c-2.896,1.925-6.105,3.335-9.497,4.101c0.467,5.545,0.92,11.09,1.196,16.653c20.19-9.09,39.935-19.372,58.074-32.129
		c14.397-10.212,28.023-21.951,38.235-36.469c5.662-8.198,10.44-17.396,11.529-27.435c0.92,4.05,2.088,8.049,2.705,12.169
		c2.046,12.071,1.387,24.595-1.999,36.366c-4.288,15.084-12.743,28.701-23.072,40.384c-12.001,13.584-26.426,24.842-41.706,34.502
		c-12.258,7.675-25.108,14.43-38.483,19.947c-2.149,0.888-4.199,1.976-6.194,3.148c-2.789,33.928-9.758,67.426-19.512,100.009
		c1.233-0.766,1.878-2.116,2.779-3.205c15.383-19.863,35.969-35.357,58.592-46.092c7.39-3.508,14.976-6.633,22.773-9.133
		c11.641-3.046,23.543-5.18,35.539-6.185c10.87-1.084,21.923,0.509,32.685-1.864c5.606-1.196,11.865-3.232,14.818-8.581
		c-2.247,6.563-5.129,12.958-9.184,18.62c-4.802,6.834-11.151,12.701-18.704,16.354c-3.812,1.929-7.927,3.162-12.104,3.999
		c-10.744,2.21-21.563,4.041-32.26,6.465c-19.554,4.372-38.884,10.412-56.64,19.816c-19.138,10.025-36.212,24.3-48.442,42.201
		c-1.481,1.981-2.023,4.447-3.247,6.563c-1.172,1.939-3.429,2.649-5.176,3.91c-1.173-0.691-2.593-0.561-3.821-1.042
		c-1.752-0.799-3.756-0.369-5.517-1.107c-3.522-1.299-6.26-4.008-8.726-6.731c-3.7-4.242-6.731-9.025-9.361-13.981
		c-9.516-14.635-17.737-30.28-22.829-47.036c-1.635-5.339-2.807-10.814-3.564-16.345c-1.999-12.22-5.358-24.179-9.352-35.885
		c-9.684-27.977-23.768-54.57-42.546-77.521c-18.905-23.165-43.018-42.215-70.43-54.29c-9.095-4.05-18.517-7.334-28.126-9.927
		c7.614-3.391,15.756-5.517,23.95-6.979c18.498-3.209,37.866-1.967,55.584,4.372c8.25,2.952,16.181,6.858,23.445,11.772
		c3.882-5.12,9.231-9.104,15.327-11.183c6.367-2.284,13.29-2.798,19.97-1.873c-1.78-2.457-3.564-5.05-4.097-8.095
		c-0.575-2.658,0.5-5.512,2.522-7.278c2.527-2.294,5.905-3.256,9.146-4.05c5.241-1.345,10.473-2.99,15.196-5.685
		c1.892-1.13,3.821-2.434,4.938-4.396c1.822-3.205,5.18-5.54,8.866-5.937c2.854-0.318,5.685,0.579,8.287,1.691
		c3.728,1.607,6.932,4.704,7.969,8.721c2.742-4.541,6.077-8.759,10.324-11.991c-11.225-24.627-25.744-47.736-43.088-68.514
		c-11.435-13.757-24.057-26.519-37.576-38.235c-10.445-8.96-21.334-17.452-32.849-24.987c-6.47,4.97-12.267,10.767-18.003,16.551
		c-12.412,12.841-24.202,26.258-36.208,39.473c-11.767,12.855-24.445,24.964-38.496,35.311c-7.002,5.152-14.364,9.842-22.114,13.79
		c-7.124,3.513-14.575,6.657-22.474,7.838c-10.945,2.009-22.133,3.055-33.246,2.205c-17.242-1.116-34.372-6.932-47.965-17.742
		c-12.374-9.786-21.979-23.067-27.374-37.889c-1.009-3.078-2.228-6.255-1.934-9.553c0.112-1.009,0.257-2.233,1.21-2.817
		c1.126-0.201,1.967,0.752,2.644,1.509c4.952,6.185,10.716,11.776,17.359,16.13c7.857,5.241,16.915,8.619,26.253,9.978
		c17.882,2.579,36.03-1.537,52.59-8.212c16.163-6.54,31.256-15.485,45.415-25.604c7.806-5.634,15.369-11.604,22.563-17.999
		c6.834-5.937,12.916-12.669,19.648-18.713c5.573-5.059,11.389-9.885,17.662-14.061c3.882-2.551,7.96-4.919,12.449-6.236
		c0.504-0.285,1.331-0.252,1.588-0.86c-4.181-2.233-8.469-4.447-13.122-5.522c-7.885-2.027-16.154-2.821-24.254-1.85
		c-7.25,1.542-14.472-1.504-20.503-5.307c-4.008-2.583-7.787-5.554-11.113-8.978c-0.789-0.958-2.163-0.738-3.247-0.944
		c6.311,6.666,8.478,16.588,6.713,25.454c-0.715,4.186-3.569,7.782-3.471,12.145c-0.383,5.596,2.336,10.814,5.802,15.009
		c-0.462,0.332-0.859,0.981-1.499,0.649c-4.036-1.14-8.273-1.602-12.454-1.201c-5.909,0.453-11.762,1.925-17.718,1.509
		c-7.894-0.593-15.406-4.4-20.839-10.113c-3.891-4.045-6.778-9.016-8.591-14.313c-1.901,3.658-5.788,5.863-9.842,6.129
		c-4.91,0.799-9.632-2.252-14.509-0.93c-4.078,1-6.872,4.578-8.563,8.222c-1.112,2.093-1.429,4.779-3.564,6.161
		c-1.812-1.018-1.812-3.265-1.78-5.078c0.21-5.031-0.5-10.099-2.121-14.869c-1.542-4.681-3.821-9.16-4.513-14.084
		c-0.729-4.741,0.285-9.562,1.957-14c1.887-5.101,4.835-9.805,8.642-13.696c1.373-1.467,3.041-2.621,4.428-4.078
		c-4.466-0.556-8.072-3.812-10.188-7.619c-1.64-2.892-2.817-6.306-2.041-9.646c0.584-2.831,2.28-5.367,4.569-7.11
		c2.691-2.322,3.788-5.895,4.821-9.17c1.42-4.882,1.976-9.95,2.574-14.976c0.584-3.784,2.14-8.123,5.993-9.656
		c4.036-1.49,8.352,0.383,11.832,2.443c-2.28-8.306-2.448-17.387,0.668-25.505c1.957-5.157,5.288-9.824,9.684-13.187
		c6.937-5.269,13.682-10.908,19.311-17.592c0.416-0.808,1.565-0.514,1.598,0.388c1.079,4.527,3.616,8.651,7.077,11.753
		c1.925,1.855,4.368,3.074,6.227,5.008c4.634,4.592,7.255,10.8,8.847,17.032c4.027-3.12,8.689-5.531,13.776-6.278
		c9.964-1.434,20.105,2.289,27.939,8.348c2.434,1.714,3.821,4.583,6.503,5.993c1.71,1.098,3.765,1.308,5.671,1.897
		c0.196,0.392,0.187,0.785-0.023,1.168c-1.285,0.551-2.733,0.906-3.658,2.027c-2.457,2.775-3.555,6.414-4.751,9.847
		c-1.766,5.18-3.06,10.819-6.872,14.962c-5.844,6.451-14.897,8.759-23.315,8.932c5.699,4.769,11.842,9.02,17.812,13.458
		c7.441-2.261,15.911-0.392,21.852,4.587c3.774,2.971,6.358,7.1,8.978,11.057c4.896,7.582,9.324,15.457,13.631,23.389
		c3.018-0.654,5.755,1.009,8.376,2.252c6.404,3.074,12.608,6.559,18.755,10.118c3.569-0.318,7.119-1.14,10.716-1.415
		c13.061-1.327,26.351-0.775,39.188,2.037c6.545,1.644,13.285,2.607,19.601,5.106c12.454,4.559,23.623,12.421,32.382,22.362
		c0.916,1.065,1.775,2.205,2.868,3.116c-2.663-7.988-5.9-15.784-9.072-23.59c-14.07-33.876-31.611-66.422-53.351-96.015
		c-20.199-27.519-44.06-52.459-71.355-73.013c-15.088-11.407-31.223-21.437-48.147-29.887c-3.578-1.691-7.096-3.56-10.772-5.012
		c-5.017-0.089-10.034,0.719-14.948,1.668c-7.1,1.537-14.505,3.877-19.713,9.189c-2.354,2.896-4.073,6.255-6.526,9.086
		c-10.767,13.164-23.754,24.478-38.001,33.746c-11.449,7.325-24.062,13.22-37.567,15.42c-19.97,5.255-40.575,7.871-61.185,8.749
		c-2.111,1.205-3.887,2.929-5.862,4.344c-14.92,11.636-29.598,23.604-43.826,36.063c-5.297,13.355-14.243,24.95-24.352,35.035
		c-12.43,12.239-26.846,22.324-42.089,30.737c-25.314,13.949-52.833,23.45-80.777,30.565c-11.926,4.298-24.193,7.563-36.478,10.665
		c-34.306,9.74-70.042,14.93-105.736,13.865c-21.105-0.939-42.36,0.453-63.058,4.737c-18.905,4.027-37.59,10.249-54.103,20.451
		c-0.374-0.243-0.752-0.476-1.135-0.696l0.014-0.355c0.089-0.135,0.262-0.411,0.35-0.551c13.953-8.572,29.467-14.35,45.284-18.424
		c21.549-5.498,43.887-7.61,66.099-7.171c40.575,1.948,81.356-3.989,120.091-16.041c3.504-0.827,6.624-2.705,9.478-4.835
		c-2.864,0.154-5.718-0.023-8.563-0.238c-34.232,2.541-69.033,1.411-102.517-6.615c-9.193-2.252-18.265-5.045-27.061-8.549
		c-4.671-1.831-9.184-4.031-13.883-5.788c-17.443-6.694-36.436-10.034-55.089-7.792c-16.91,1.957-33.405,8.413-46.718,19.101
		c-0.29-0.481-0.603-0.944-0.925-1.387c13.981-11.431,31.644-17.929,49.53-19.708c16.508-1.621,33.241,0.99,48.876,6.358
		c5.891,1.948,11.538,4.531,17.242,6.946c17.261,7.044,35.535,11.351,53.973,13.897c18.858,2.593,37.936,3.321,56.958,2.803
		c13.598-0.593,27.374-0.925,40.58-4.573c8.371-2.537,16.91-4.475,25.258-7.1c19.297-5.858,38.212-13.154,56.051-22.6
		c15.518-8.231,30.233-18.162,42.962-30.312c6.11-5.928,11.748-12.384,16.499-19.461c1.219-1.948,2.644-3.802,3.466-5.97
		c-8.156,4.821-16.275,9.73-24.623,14.224c-30.807,16.747-62.881,31.097-95.617,43.626c-3.929,1.668-8.161,2.336-12.276,3.359
		c-31.013,7.035-63.04,10.576-94.814,7.937c-18.62-1.546-37.1-5.246-54.729-11.477c-15.985-5.62-31.247-13.29-45.261-22.815
		c-22.236-15.009-41.21-34.54-56.78-56.318c-4.05-5.755-8.044-11.557-12.421-17.069c-13.005-16.569-27.991-31.559-44.434-44.714
		c-11.328-8.992-23.245-17.345-36.025-24.151c-0.299-0.322-1.359-0.322-1.051-0.934c7.348-3.933,16.107-3.69,24.085-2.219
		c13.584,2.653,26.187,8.843,37.969,15.929c23.329,14.22,43.784,32.559,63.268,51.576c25.987,24.478,54.958,46.027,86.863,62.175
		c9.217,4.611,18.662,8.801,28.411,12.169c16.98,5.662,34.75,8.848,52.609,9.819c31.172,1.705,62.414-2.882,92.469-10.982
		c28.565-7.81,56.304-18.676,82.57-32.358c2.723-1.556,5.807-2.709,7.979-5.054c-47.377,12.075-95.856,20.404-144.751,22.563
		c-18.662,0.705-37.375,0.514-55.981-1.238c-21.11-1.985-42.149-5.877-62.143-13.061c-15.878-5.713-31.097-13.617-44.195-24.328
		c-18.709-15.028-32.335-36.039-38.954-59.064c-0.159-0.547-0.378-1.098-0.878-1.406c1.649,0.495,3.415,0.131,4.998-0.448
		c-0.159,0.107-0.476,0.332-0.635,0.439c4.428,15.327,11.856,29.85,22.1,42.107c9.978,12.033,22.553,21.754,36.273,29.163
		c18.274,9.889,38.431,15.859,58.821,19.517c33.526,5.905,67.818,5.872,101.672,3.261c23.819-1.925,47.517-5.246,71.004-9.632
		c21.11-3.971,42.075-8.745,62.764-14.523c0.911-0.22,1.724-0.719,2.42-1.35c12.72-10.968,25.8-21.525,38.978-31.943
		c-37.403,0.107-75.246-6.988-108.884-23.726c-21.479,11.884-44.181,21.633-67.725,28.626c-17.485,5.176-35.483,8.735-53.683,10.095
		c-12.127,0.827-24.38,0.976-36.441-0.719c-17.452-2.42-34.418-8.773-48.867-18.91c-12.071-8.474-22.563-19.363-29.948-32.171
		c-3.219-5.615-5.82-11.884-5.489-18.48c0.112-0.663,0.313-1.299,0.603-1.906c1.093,0.85,1.971,1.948,2.466,3.251
		c2.878,6.727,8.334,12.024,14.425,15.906c7.311,4.625,15.542,7.596,23.903,9.665c11.893,2.957,24.16,4.115,36.385,4.498
		c12.954,0.341,25.921-0.364,38.8-1.742c17.284-1.822,34.283-6.18,50.31-12.893c9.044-3.751,17.877-8.002,26.542-12.557
		c-14.481-8.749-28.766-17.821-43.116-26.781c-15.598-9.656-31.503-19.059-48.731-25.533c-11.024-4.2-22.502-7.147-34.124-9.142
		c-21.689-3.686-43.887-5.676-65.824-3.181c-12.127,1.509-24.468,4.34-34.848,11.062c-4.76,3.102-8.988,7.18-11.664,12.234
		c-0.668,1.317-1.556,2.523-2.709,3.466c-1.074-1.327-0.57-3.125-0.397-4.667c0.897-7.114,4.984-13.262,9.114-18.928
		C237.693,85.351,258.55,71.954,281.262,66.026 M269.799,85.79c-0.92,0.369-1.794,0.836-2.602,1.406
		c14.378-1.873,28.939-2.415,43.415-1.537c20.418,1.21,40.631,5.456,59.868,12.393c17.961,6.432,35.04,15.121,51.394,24.903
		c15.584,9.291,30.536,19.578,45.662,29.588c10.693,7.063,21.502,13.986,32.825,20.007c18.078,9.539,37.487,16.336,57.214,21.554
		c22.703,5.951,45.915,9.847,69.257,12.206c0.663-0.5,1.35-0.981,2.013-1.499c0.906-0.355-0.112-0.78-0.486-0.71
		c-25.384-4.662-50.661-10.254-75.236-18.209c-19.685-6.372-38.805-14.701-56.374-25.678c-12.706-7.843-24.655-16.817-37.03-25.15
		c-13.084-8.89-26.725-16.966-40.883-24.02c-18.76-9.436-38.225-17.588-58.415-23.417c-19.764-5.671-40.36-9.184-60.975-8.109
		C289.344,80.152,279.16,81.796,269.799,85.79 M633.037,114.486c-20.727,2.653-41.332,6.703-61.232,13.154
		c-19.026,6.026-36.852,15.21-54.131,25.085c3.345,1.85,6.713,3.667,10.081,5.484c15.028-9.09,30.672-17.275,47.227-23.226
		c19.545-7.138,39.903-11.776,60.368-15.369c22.296-3.868,44.798-6.488,67.333-8.521c-3.751-0.561-7.563-0.178-11.337-0.29
		C671.86,110.814,652.372,112.071,633.037,114.486 M570.413,154.842c-8.226,3.868-16.476,7.764-24.263,12.468
		c29.892,13.659,61.461,23.749,93.833,29.481c27.388-19.587,56.033-37.735,86.91-51.333c16.756-7.231,34.26-13.313,52.496-15.247
		c-0.528-0.626-1.327-0.794-2.037-1.07c-12.533-4.204-25.351-7.698-38.426-9.712C681.026,118.153,623.031,130.798,570.413,154.842
		 M772.584,136.862c-10.305,1.747-20.344,4.807-30.135,8.418c-16.294,6.091-31.914,13.855-47.036,22.422
		c-16.331,9.273-32.092,19.521-47.433,30.345c8.287,1.607,16.728,2.387,25.132,3.209c4.685,0.206,9.464,1.364,14.089,0.112
		c5.479-1.168,10.8-3.022,15.915-5.293c14.411-6.479,27.304-15.943,38.856-26.641c6.918-6.545,13.566-13.528,18.592-21.656
		c2.415-3.489,5.928-6.063,9.637-8.053c2.574-1.499,5.517-2.167,8.151-3.522C776.4,136.11,774.494,136.572,772.584,136.862
		 M441.55,191.754c-18.905,7.497-38.707,12.874-58.934,15.177c-23.352,2.709-47.12,1.406-70.126-3.331
		c0.738,0.621,1.56,1.14,2.424,1.574c7.292,3.784,15.275,6.049,23.329,7.526c21.554,3.816,43.714,1.967,65.062-2.289
		c28.065-5.764,55.14-15.728,81.01-27.949c0.009-0.075,0.033-0.229,0.042-0.304c-3.312-1.784-6.549-3.69-9.838-5.517
		C463.715,182.057,452.822,187.354,441.55,191.754 M957.106,177.526c-0.803,7.852,1.158,15.752,4.583,22.791
		c3.929,8.189,9.52,15.471,15.677,22.105c5.951,6.358,12.533,12.113,19.526,17.303c14.075,10.001,27.131,21.376,39.51,33.395
		c6.568,7.119,11.991,15.271,16.289,23.945c6.479,13.173,10.179,27.608,11.604,42.191c1.682,18.172,0.346,36.679-4.228,54.36
		c1.429-1.607,2.336-3.592,3.373-5.456c3.812-7.558,6.035-15.826,7.376-24.155c4.396-28.313-1.784-58.177-17.209-82.342
		c-8.432-13.304-19.587-24.8-32.302-34.054c-19.353-13.556-38.043-28.696-52.244-47.76
		C964.029,193.034,959.684,185.626,957.106,177.526 M144.215,183.491c4.984,3.989,9.889,8.091,14.589,12.412
		c24.763,22.768,47.194,47.919,68.851,73.611c11.118,12.79,25.164,22.81,40.304,30.303c18.111,8.992,37.744,14.612,57.626,18.036
		c19.101,3.237,38.538,4.498,57.896,3.784c9.109-0.425,18.251-1.13,27.182-3.055c2.266-0.57,4.615-0.986,6.713-2.065
		c-2.565,0.103-5.082,0.677-7.638,0.874c-11.515,1.13-23.109,1.219-34.657,0.579c-36.731-2.107-73.008-11.884-105.806-28.556
		c-17.588-9.31-33.947-20.965-48.428-34.619c-17.943-17.237-34.255-36.086-52.282-53.239
		C161.125,194.725,153.474,187.76,144.215,183.491 M742.584,207.88c-3.831,2.934-6.876,6.862-8.763,11.3
		c-3.345,7.857-3.466,16.868-1.196,25.034c0.775,3.247,2.714,6.012,4.391,8.843c4.723,7.605,10.431,14.612,17.13,20.563
		c0.43,0.075,0.869,0.159,1.313,0.248c0.715,1.873,2.751,2.625,4.232,3.774c-2.121-3.915-4.083-7.918-5.648-12.085
		c-3.191-8.609-5.409-17.938-4.13-27.145c0.154,0.005,0.458,0.005,0.607,0.009c-0.846,4.442-0.383,9.011,0.322,13.444
		c1.121,6.573,3.279,12.94,6.063,18.984c1.359,2.653,2.42,5.531,4.265,7.89c-0.49-2.574-1.313-5.073-1.658-7.675
		c-1.079-5.736-0.822-11.585-0.789-17.387c0.098-0.131,0.294-0.392,0.392-0.523l0.252,0.238c-0.612,8.436,0.005,17.041,2.466,25.169
		c0.107,0.635,0.906,0.724,1.364,1.065c-1.158-3.186-1.957-6.521-2.107-9.913c-0.467-8.095,1.953-16.079,5.554-23.249
		c4.134-8.142,9.74-15.495,16.158-21.974c-1.467-6.026-3.831-12.047-8.128-16.639c-2.023-2.233-4.83-3.532-6.942-5.666
		c-3.125-2.91-5.536-6.591-6.853-10.66C755.398,197.622,749.115,202.942,742.584,207.88 M635.681,207.151
		c4.438,0.159,8.857,0.836,13.304,0.701c0.229,0.093,0.687,0.29,0.916,0.383c-1.088-0.864-2.523-0.846-3.821-1.056
		c-2.691-0.36-5.358-0.869-8.03-1.313C637.017,205.535,636.349,206.577,635.681,207.151 M789.709,221.749
		c-4.326,2.401-7.647,6.152-10.809,9.875c-7.409,8.974-13.809,19.367-15.598,31.046c-0.953,6.423-0.028,13.15,2.887,18.97
		c0.276-0.364,0.565-0.724,0.864-1.084c-0.841,1.574,1.191,1.943,1.967,2.714c-1.546-5.022-1.018-10.44,0.495-15.397
		c2.522-8.114,7.353-15.303,12.785-21.754c2.097-2.382,4.139-4.891,6.685-6.815c-0.121,0.472-0.35,0.883-0.687,1.219
		c-6.054,5.961-11.398,12.72-15.289,20.292c-3.546,6.937-5.708,15.144-3.34,22.815c0.906,0.612,1.78,1.294,2.756,1.822
		c-0.005-0.411-0.019-1.233-0.028-1.64c0.248-0.075,0.743-0.224,0.99-0.299c3.966-6.755,10.342-12.351,17.985-14.434
		c3.639-1.116,7.46-0.794,11.206-1.042c7.283-0.598,14.981-3.088,19.699-8.974c3.228-4.092,4.288-9.329,6.003-14.131
		c1.387-3.901,2.723-8.184,6.077-10.875c-2.111-0.817-4.162-1.92-5.699-3.611c-3.915-4.914-9.628-8.063-15.457-10.16
		C805.624,217.559,796.772,217.596,789.709,221.749 M720.237,244.358c-3.415,1.07-4.709,4.905-5.246,8.095
		c-0.617,4.648-1.037,9.343-2.242,13.888c-1.084,3.938-2.312,8.114-5.218,11.118c-2.051,1.593-3.886,3.648-4.489,6.246
		c-0.939,3.429,0.57,6.914,2.382,9.772c1.92,3.05,5.106,5.582,8.838,5.685c2.705,0.257,4.737-1.836,7.203-2.546
		c10.581-2.583,21.063-6.213,32.022-6.769c-0.682-0.944-1.313-1.915-1.869-2.924c-7.955-0.766-15.854,0.766-23.698,1.854
		c0.645-0.878,1.803-0.752,2.761-0.972c6.932-1.009,13.963-2.182,20.979-1.397c-0.07-0.35-0.21-1.051-0.28-1.401
		c-8.857-0.117-17.013-4.55-23.688-10.071c-0.836-0.794-1.995-1.397-2.317-2.583c1.546,0.958,2.77,2.336,4.246,3.401
		c6.246,4.797,13.72,8.614,21.759,8.661c0.178-1.224,0.472-2.434,0.911-3.588c-2.396,0.135-4.69-0.71-6.858-1.626
		c-4.218-1.897-7.946-4.727-11.351-7.824c-1.897-1.854-4.022-3.592-5.367-5.905c1.243,0.589,1.981,1.808,2.952,2.737
		c3.788,3.901,8.016,7.488,12.902,9.936c2.719,1.261,5.746,2.597,8.81,2.051c-0.037-0.252-0.103-0.761-0.14-1.014
		c0.696,0.112,1.247-0.089,1.658-0.598c0.687-0.392,1.598-0.047,2.382-0.154c-10.651-7.708-18.793-18.391-25.094-29.822
		c-0.631-1-1.78-1.476-2.728-2.097C726.692,244.97,723.451,243.527,720.237,244.358 M1165.349,251.431
		c2.205,26.496-0.617,53.291-7.021,79.053c-5.783,23.048-14.822,45.298-26.949,65.74c-11.837,19.993-26.622,38.221-43.621,54.047
		c-3.377,3.303-7.114,6.218-10.543,9.445c5.914-3.13,11.459-6.932,16.849-10.889c18.055-13.458,33.713-30.144,45.915-49.077
		c12.762-19.746,21.572-42.005,25.954-65.1C1171.183,307.211,1170.389,278.828,1165.349,251.431 M791.862,270.999
		c-4.2,1.546-7.82,4.298-11.155,7.217c-2.28,2.083-4.643,4.204-6.119,6.965c-1.177,2.13-2.597,4.41-2.228,6.965
		c0.575,5.148,3.05,9.852,5.671,14.238c2.289,3.074,5.101,5.928,8.553,7.68c8.161,2.63,16.836,3.363,25.375,2.807
		c2.065-0.196,4.153,0.173,6.222-0.112c-0.341-0.294-0.696-0.565-1.06-0.803c-7.086-4.27-11.874-11.426-15.214-18.83
		c8.847-4.47,19.223-4.723,28.771-2.723c-11.678-6.857-22.268-15.369-33.134-23.417
		C795.819,269.882,793.661,270.392,791.862,270.999 M754.636,281.122c-2.158,2.298-1.56,6.484,1.257,8.007
		c4.33,2.448,9.544,2.495,14.388,2.27c0.149-1.471,0.5-2.906,1.065-4.274c-3.592-2.537-7.334-4.998-11.547-6.362
		C758.14,280.285,756.052,279.846,754.636,281.122 M747.499,292.366c-8.273,1.406-16.396,3.578-24.426,5.979
		c-1.925,0.906-3.817,1.883-5.849,2.532c-6.834,4.013-12.066,10.529-14.934,17.882c-1.682,4.26-2.779,8.899-2.139,13.491
		c0.621,4.76,2.817,9.086,4.321,13.594c1.822,5.241,2.672,10.837,2.322,16.382c-0.019,0.514,0.075,1.018,0.28,1.509
		c1.275-3.508,2.859-7.03,5.531-9.712c2.252-2.308,5.395-3.765,8.642-3.784c3.812-0.215,7.395,1.668,11.221,1.317
		c3.415-0.168,7.227-1.528,8.81-4.797c1.247-2.322,0.434-4.97,0.327-7.441c-0.654-8.922,0.584-18.087,4.372-26.244
		c3.7-8.091,9.782-15.06,17.289-19.834c-0.771-0.126-1.537-0.248-2.289-0.364c-6.666,3.233-12.627,7.941-17.022,13.925
		c-5.54,7.409-9.09,16.191-10.908,25.225c-0.327,1.238-0.192,2.644-0.967,3.732c-0.103-2.522,0.654-4.966,1.201-7.399
		c3.05-11.94,9.291-23.483,19.25-31.018c2.317-1.925,5.115-3.102,7.558-4.821c-0.346,0.047-1.028,0.131-1.369,0.177
		c-7.32,3.191-13.85,8.025-19.349,13.79c-5.746,6.012-10.595,12.884-14.369,20.292c-0.229,0.378-0.533,0.677-0.911,0.906
		c1.056-3.05,2.943-5.741,4.597-8.497c5.708-8.848,12.888-16.924,21.768-22.693c2.466-1.752,5.311-2.831,7.899-4.363
		c-2.938,0.35-5.722,1.49-8.558,2.289c-6.017,1.967-11.875,4.555-17.13,8.109c-5.774,3.971-11.132,9.352-12.991,16.303
		c0.056,0.505-0.462,0.458-0.747,0.603c1.528-6.787,6.442-12.239,11.889-16.289c7.736-5.629,16.845-9.174,26.136-11.239
		C753.8,291.469,750.619,291.866,747.499,292.366 M765.969,294.799c-0.145-0.29-0.28-0.575-0.402-0.859
		c-6.344,4.083-11.982,9.399-15.77,15.971c-5.806,9.772-7.427,21.605-5.919,32.76c2.401,11.034,10.025,21.208,20.759,25.272
		c5.05,2.037,10.641,2.27,15.99,1.513c6.638-0.864,13.449-2.139,20.091-0.537c-3.943-5.704-5.428-13.365-2.56-19.848
		c3.606-7.222,3.321-15.976,0.145-23.291c-1.378-3.013-3.34-5.961-6.283-7.628c-1.457-0.85-3.172-0.953-4.709-1.574
		c-2.714-1.07-5.082-2.854-7.18-4.844c-4.975-4.956-8.997-11.323-9.786-18.424c-0.36,0.023-1.07,0.061-1.429,0.084
		c-1.369,1.588-2.597,3.62-2.27,5.797c-0.196,0.033-0.584,0.107-0.78,0.145c0.056-0.458,0.164-1.373,0.215-1.831
		c-3.457,5.979-4.793,12.968-4.952,19.811c-0.271,10.342,1.766,20.619,4.882,30.443c-0.878-0.234-0.939-1.215-1.201-1.92
		c-1.705-5.629-2.976-11.398-3.676-17.237c-1.051-9.366-1.009-19.237,2.761-28.037c1.014-2.607,2.892-4.76,3.854-7.385
		C767.034,293.566,766.371,294.08,765.969,294.799 M909.874,348.235c1.439,1.238,3.046,2.261,4.578,3.387
		c21.754,15.724,41.776,33.876,59.373,54.155c19.75,22.768,36.413,48.264,48.941,75.699c0.159,0.855,0.911,1.042,1.691,0.93
		c-5.218-29.84-12.253-59.373-21.152-88.335c-0.57-1.855-1.897-3.354-2.457-5.218c-7.96-18.185-22.497-33.802-40.911-41.589
		c-7.689-3.158-15.901-5.55-24.282-5.321C926.705,341.938,917.82,344.143,909.874,348.235 M881.907,354.401
		c-7.75,4.265-15.032,9.329-22.119,14.612c-13.598,10.221-26.384,21.483-38.711,33.19c-13.458,13.08-29.528,23.287-46.615,30.938
		c-18.031,7.955-37.385,13.36-57.135,14.29c-11.87,0.406-24.296-0.785-34.839-6.671c0.757,0.803,1.621,1.514,2.551,2.135
		c7.456,5.213,16.037,8.721,24.917,10.627c19.092,4.148,39.09,0.789,57.07-6.297c17.153-6.708,32.783-16.728,47.47-27.729
		c7.815-5.961,15.462-12.244,22.025-19.587c8.581-10.062,17.667-19.685,27.066-28.986c5.82-5.62,11.576-11.379,18.153-16.125
		C881.781,354.7,881.865,354.499,881.907,354.401 M1021.584,484.871c-9.642,3.279-17.989,10.595-21.969,20.031
		c-0.43,1.873-0.799,3.765-1.546,5.55c-0.696,3.018-0.925,6.124-1.369,9.189c-0.869,8.035-1.957,16.167-0.967,24.239
		c0.187-0.005,0.565-0.014,0.757-0.019c-0.019-3.461,0.878-6.848,1.556-10.221c2.172-9.226,5.919-18.55,13.019-25.094
		c2.182-1.789,4.522-3.84,7.409-4.237c-1.014,0.99-2.485,1.266-3.616,2.083c-5.844,3.704-9.81,9.707-12.543,15.943
		c-3.475,7.932-4.952,16.546-5.919,25.104c-0.35-1.065-0.322-2.826-2.018-2.079c-1.294-3.265-0.85-6.876-0.92-10.305
		c0.262-6.591,0.972-13.164,1.869-19.699c0.505-2.966,0.336-6.059,1.593-8.866c1.401-2.751,0.444-6.11-1.383-8.422
		c-2.424-2.994-6.166-4.62-9.88-5.293c-2.878-0.481-5.895,0.486-8.011,2.476c-1.798,1.504-2.471,3.952-4.41,5.335
		c-6.091,4.816-13.808,6.773-21.18,8.707c-2.751,0.808-5.68,1.995-7.292,4.503c-1.252,1.953-0.897,4.438,0.005,6.456
		c1.107,2.466,2.7,4.737,4.667,6.591c10.95,4.279,21.619,9.819,30.322,17.84c1.579,1.308,2.7,3.13,4.48,4.204
		c0.42,1.892,2.714,2.934,2.625,4.98c0.874,0.299,1.999,1.518,0.612,2.083c-3.835-0.724-7.703-1.719-11.646-1.355
		c-9.142,0.547-17.583,4.896-24.749,10.361c-5.774,4.438-10.926,9.651-15.434,15.364c-3.111,3.887-5.33,8.572-5.956,13.538
		c-0.738,5.078,0.304,10.277,2.275,14.972c2.191,5.064,5.4,9.712,9.436,13.481c1.663,1.654,3.943,2.499,5.55,4.223
		c1.116,1.07,1.943,2.392,2.691,3.742c0.799-1.327,1.845-2.499,3.083-3.438c5.634-4.358,12.818-6.115,18.428-10.506
		c6.096-5.045,8.072-13.425,7.811-21.021c-0.112-4.228-1.219-8.432-0.547-12.669c0.766-5.839,3.625-11.258,7.521-15.616
		c1.411-1.658,3.167-2.976,4.615-4.592c-2.588,0.78-5.227,1.392-7.712,2.466c-6.675,2.677-12.472,7.577-16.009,13.865
		c-2.966,5.26-4.695,11.277-4.732,17.331c-0.168,0.005-0.495,0.019-0.663,0.023c0.294-8.521,3.298-17.116,9.128-23.436
		c5.138-5.652,12.323-9.203,19.774-10.613c2.013-0.206,3.326-1.999,5.208-2.593c4.723-1.696,9.917-0.584,14.472,1.149
		c5.405,2.205,10.567,5.507,14.037,10.286c1.56,2.004,1.85,4.732,3.681,6.559c2.088,2.182,4.91,3.513,7.778,4.368
		c7.661,2.154,16.354,1.154,23.002-3.359c6.017-3.76,13.883-3.391,20.059-0.196c-4.078-9.969-0.453-21.096-3.961-31.167
		c-3.167-9.044-10.833-16.116-19.783-19.288c-4.484-1.598-9.287-2.705-14.061-2.074c-11.211,0.743-22.418,4.9-30.597,12.753
		c-4.545,4.176-7.923,9.427-10.595,14.948c0.252,0.206,0.514,0.42,0.789,0.645c-0.673,0.589-1.21,1.28-1.616,2.074
		c1.64-0.775,3.018-1.967,4.405-3.12c5.288-4.055,11.786-6.203,18.284-7.362c9.324-1.602,18.886-1.303,28.224,0.023
		c1.089,0.187,2.27,0.248,3.251,0.845c-1.583,0.178-3.125-0.285-4.676-0.458c-9.758-1.299-19.778-1.429-29.429,0.705
		c-5.573,1.331-11.258,3.303-15.644,7.091c5.755-2.182,12.099-2.097,18.069-0.93c8.002,1.579,15.574,4.877,22.628,8.918
		c0.813,0.514,1.798,0.897,2.214,1.855c-3.078-1.542-5.965-3.466-9.132-4.849c-7.152-3.34-14.832-5.839-22.763-6.194
		c-4.172-0.075-8.404,0.603-12.23,2.284c-1.565,0.631-2.167,2.476-3.704,3.106l0.621-1.06c-0.266,0.005-0.803,0.014-1.074,0.019
		c0.252-0.252,0.761-0.752,1.014-1c-0.495-0.336-0.967-0.682-1.411-1.037c-0.159-0.771,0.271-1.495,0.519-2.191
		c4.643-11.155,13.874-20.269,25.038-24.879c6.811-2.878,14.215-4.139,21.586-4.298c2.99,0.009,5.704-2.144,6.68-4.924
		c1.364-3.723,0.635-7.866-0.808-11.454c-1.205-2.864-1.901-6.068-1.21-9.146c0.691-3.391,2.952-6.232,5.61-8.343
		c1.733-1.471,3.798-2.448,5.676-3.704c-3.144,0.201-6.246,0.976-9.417,0.92c-5.414,0.084-10.693-1.233-15.925-2.438
		C1030.698,483.512,1025.943,483.381,1021.584,484.871 M916.087,536.48c-2.266,2.13-4.111,4.676-5.582,7.409
		c-3.812,6.876-8.156,13.463-13.14,19.545c5.545,0.92,10.637,3.924,14.444,8.016c5.251,6.115,13.556,8.408,21.311,9.039
		c6.717-8.913,15.014-16.812,24.777-22.315c8.081-4.536,17.774-6.918,26.94-4.685c-0.355-0.411-0.472-1.233-1.098-1.238
		c-15.658-2.317-31.653,1.415-45.83,8.067c0.477-1.032,1.672-1.294,2.597-1.775c13.449-5.937,28.486-8.974,43.149-6.881
		c-0.649-0.902-1.775-0.967-2.7-1.359c-4.854-1.742-9.819-3.19-14.892-4.106c-7.923-1.406-16.219-1.775-24.025,0.453
		c0.472-1.154,1.967-0.981,2.98-1.322c12.65-2.317,25.646,0.514,37.52,4.956c-1.457-1.359-2.56-3.317-4.611-3.849
		c-14.509-5.989-30.364-9.24-46.078-7.792c0.252-0.607,0.836-0.696,1.434-0.673c15.415-1.177,30.948,2.135,45.139,8.081
		c-7.857-7.133-17.265-12.365-27.056-16.345c-4.036-1.808-8.553-2.037-12.907-1.943C930.33,528.081,922.076,530.837,916.087,536.48
		 M852.987,534.784c15.803,6.498,30.7,15.28,43.943,26.085c2.032-2.485,3.849-5.134,5.746-7.722
		c-7.656-5.578-16.102-10.076-25.001-13.323C869.743,537.055,861.428,534.85,852.987,534.784 M1127.479,574.593
		c-13.986,13.056-30.756,22.712-47.951,30.887c-9.137,4.438-18.811,7.698-27.696,12.655c-0.005,3.583-0.084,7.171-0.126,10.758
		c10.258-5.979,20.802-11.487,31.653-16.317c8.539-3.531,16.845-7.675,24.627-12.678c7.993-5.157,15.583-11.113,21.764-18.391
		c5.582-6.549,9.987-14.472,10.875-23.156C1137.242,564.494,1132.561,569.81,1127.479,574.593 M996.756,561.079
		c-6.288,2.551-10.16,8.502-13.365,14.182c-1.761,3.457-3.583,7.213-3.209,11.202c0.149,1.878,1.756,3.074,2.985,4.307
		c5.279,5.153,10.426,10.436,15.537,15.756c3.518,3.784,7.072,7.558,10.151,11.72c-0.659-4.952-1.518-10.025-0.532-14.995
		c0.402-1.995,1.448-4.172,3.522-4.844c1.92-0.42,3.863,0.304,5.624,1.037c5.531,2.513,10.179,6.521,14.85,10.328
		c-3.387-5.251-3.475-11.716-4.232-17.714c-0.766-6.091-2.252-12.108-4.709-17.737c-1.004-3.424-3.877-5.778-6.605-7.848
		c-3.092-2.373-6.872-3.55-10.557-4.648C1003.156,561.028,999.891,560.388,996.756,561.079 M907.347,570.071
		c7.469,7.222,14.336,15.051,20.47,23.441c0.476-3.709,1.742-7.301,3.672-10.496c-0.874-1.229-2.476-0.981-3.756-1.289
		c-5.283-1-10.539-2.91-14.696-6.4C910.995,573.724,909.514,571.51,907.347,570.071 M1027.54,580.026
		c1.383,4.657,2.27,9.45,2.733,14.285c0.322,3.429,0.743,6.876,1.714,10.197c0.537,1.696,1.121,3.662,2.901,4.452
		c-0.402,0.617-0.78,1.247-1.14,1.887c0.827-0.472,1.621-1,2.411-1.546c-0.271-8.572-0.808-17.134-1.654-25.664
		C1032.025,582.754,1029.656,581.591,1027.54,580.026 M980.514,590.761c0.458,4.639,0.523,9.375-0.575,13.935
		c-1.308,5.975-4.737,11.655-10.076,14.831c8.974,27.425,11.076,57.014,6.573,85.485c-0.691,4.872-2.28,9.595-2.499,14.537
		c-1.098,16.705,4.041,33.405,12.785,47.54c1.121,1.939,2.579,3.681,3.508,5.732c1.046,2.28,2.56,4.307,3.994,6.358
		c2.607,3.503,5.503,6.998,9.445,9.058c-0.948-3.989,1.359-7.572,2.532-11.221c-2.074-2.023-3.513-4.573-5.484-6.671
		c-4.274-4.027-7.497-9.039-9.973-14.327c-4.891-10.543-7.091-22.128-7.941-33.657c-1.107-16.812,0.846-33.764,5.143-50.035
		c1.747-6.816,4.087-13.505,7.381-19.732c8.567-16.368,21.932-29.77,36.843-40.458c-4.513-3.905-9.137-7.796-14.467-10.539
		c-1.654-0.747-3.41-1.621-5.265-1.434c-1.145,0.336-1.728,1.523-2.065,2.574c-0.738,2.415-0.659,4.975-0.575,7.474
		c0.304,4.896,1.177,9.744,2.378,14.5c-0.668-0.57-1.294-1.116-1.939-1.677c-2.07-1.574-2.882-4.181-4.657-6.021
		C997.784,607.75,989.128,599.253,980.514,590.761 M1028.063,634.896c-17.475,15.649-29.934,36.67-35.712,59.373
		c-3.191,12.627-4.559,25.837-2.962,38.809c0.687,4.657,1.504,9.431,3.532,13.71c-0.178-4.242-0.098-8.488,0.121-12.729
		c1.406-23.179,7.222-46.41,19.045-66.534c6.217-10.571,14.177-20.143,23.614-27.986c0.243-3.793,0.29-7.591,0.505-11.379
		C1033.407,630.308,1030.67,632.527,1028.063,634.896 M950.286,634.522c6.811,16.761,11.379,34.423,13.752,52.347
		c0.126,4.022,1.182,7.969,0.976,12.01c0.051-0.009,0.154-0.023,0.206-0.033c0.061-1.99-0.192-3.957-0.453-5.919
		c-0.248-0.976,0.42-2.125-0.374-2.906c-0.22-7.32-1.387-14.575-2.443-21.81c-2.018-13.257-4.914-26.384-8.89-39.192
		C951.383,630.416,950.79,632.494,950.286,634.522 M1021.075,680.305c-10.305,12.715-18.214,27.659-21.213,43.845
		c-2.649,13.977-1.584,28.663,3.191,42.079c1.056,2.396,2.728,4.475,4.344,6.521c0.631-0.079,0.467-0.976,0.78-1.359
		c11.959-34.493,21.044-70.098,25.333-106.394C1028.741,669.547,1024.84,674.924,1021.075,680.305 M1140.329,701.84
		c-4.835,0.462-9.688,0.07-14.528,0.374c-19.255,1.191-37.959,7.997-54.131,18.386c-7.152,4.723-14.065,10.123-19.18,17.078
		c3.457-2.579,6.867-5.237,10.525-7.544c17.13-11.127,36.661-19.251,57.172-20.862c12.926-1.383,25.945-3.055,38.352-7.068
		c3.424-1.336,7.497-2.527,9.403-5.961C1159.005,699.21,1149.719,701.126,1140.329,701.84z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M269.799,85.79c9.361-3.994,19.545-5.638,29.649-6.274
		c20.615-1.074,41.21,2.438,60.975,8.109c20.19,5.83,39.655,13.981,58.415,23.417c14.159,7.054,27.799,15.13,40.883,24.02
		c12.374,8.334,24.324,17.307,37.03,25.15c17.569,10.978,36.689,19.307,56.374,25.678c24.576,7.955,49.852,13.547,75.236,18.209
		c0.374-0.07,1.392,0.355,0.486,0.71c-0.663,0.519-1.35,1-2.013,1.499c-23.343-2.359-46.555-6.255-69.257-12.206
		c-19.727-5.218-39.136-12.015-57.214-21.554c-11.323-6.021-22.133-12.944-32.825-20.007
		c-15.126-10.011-30.079-20.297-45.662-29.588c-16.354-9.782-33.433-18.47-51.394-24.903c-19.237-6.937-39.449-11.183-59.868-12.393
		c-14.476-0.878-29.037-0.336-43.415,1.537C268.005,86.626,268.879,86.159,269.799,85.79z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M633.037,114.486c19.335-2.415,38.823-3.672,58.307-3.681
		c3.774,0.112,7.586-0.271,11.337,0.29c-22.534,2.032-45.036,4.653-67.333,8.521c-20.465,3.592-40.823,8.231-60.368,15.369
		c-16.555,5.951-32.199,14.135-47.227,23.226c-3.368-1.817-6.736-3.634-10.081-5.484c17.279-9.875,35.105-19.059,54.131-25.085
		C591.705,121.189,612.31,117.139,633.037,114.486z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M209.459,150.988c0.976-3.008,5.092-3.774,7.469-1.967
		c2.934,2.2,4.134,5.923,4.938,9.357c1.943-1.023,3.975-2.125,5.279-3.957c1.219-1.546,0.934-3.919,2.63-5.143
		c1.107,1.116,1.803,2.56,2.205,4.069c1.523,5.61,1.317,11.837-1.313,17.092c-2.158,4.33-6.204,7.502-10.693,9.146
		c-1.584,0.579-3.349,0.944-4.998,0.448c-7.367-2.023-14.944-6.736-17.092-14.514c1.588-2.476,4.073-4.793,7.185-4.825
		c2.158-0.168,4.087,0.934,5.895,1.957C209.656,158.971,208.25,154.874,209.459,150.988z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M441.55,191.754c11.272-4.4,22.165-9.698,32.97-15.112
		c3.289,1.826,6.526,3.732,9.838,5.517c-0.009,0.075-0.033,0.229-0.042,0.304c-25.87,12.22-52.945,22.184-81.01,27.949
		c-21.348,4.256-43.509,6.105-65.062,2.289c-8.053-1.476-16.037-3.742-23.329-7.526c-0.864-0.434-1.686-0.953-2.424-1.574
		c23.006,4.737,46.774,6.04,70.126,3.331C402.843,204.629,422.645,199.252,441.55,191.754z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M957.106,177.526c2.579,8.1,6.923,15.509,11.954,22.324
		c14.201,19.064,32.891,34.203,52.244,47.76c12.715,9.254,23.87,20.75,32.302,34.054c15.425,24.165,21.605,54.029,17.209,82.342
		c-1.341,8.329-3.564,16.597-7.376,24.155c-1.037,1.864-1.943,3.849-3.373,5.456c4.573-17.681,5.909-36.189,4.228-54.36
		c-1.425-14.584-5.124-29.018-11.604-42.191c-4.298-8.675-9.721-16.826-16.289-23.945c-12.379-12.019-25.435-23.394-39.51-33.395
		c-6.993-5.19-13.575-10.945-19.526-17.303c-6.157-6.633-11.748-13.916-15.677-22.105
		C958.264,193.277,956.302,185.378,957.106,177.526z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M144.215,183.491c9.259,4.27,16.91,11.235,24.352,18.064
		c18.027,17.153,34.339,36.002,52.282,53.239c14.481,13.654,30.84,25.309,48.428,34.619c32.797,16.672,69.075,26.449,105.806,28.556
		c11.548,0.64,23.142,0.551,34.657-0.579c2.555-0.196,5.073-0.771,7.638-0.874c-2.097,1.079-4.447,1.495-6.713,2.065
		c-8.932,1.925-18.073,2.63-27.182,3.055c-19.358,0.715-38.795-0.547-57.896-3.784c-19.881-3.424-39.515-9.044-57.626-18.036
		c-15.14-7.493-29.186-17.513-40.304-30.303c-21.656-25.692-44.088-50.843-68.851-73.611
		C154.104,191.582,149.199,187.48,144.215,183.491z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M742.584,207.88c6.531-4.938,12.813-10.258,18.298-16.354
		c1.317,4.069,3.728,7.75,6.853,10.66c2.111,2.135,4.919,3.433,6.942,5.666c4.298,4.592,6.661,10.613,8.128,16.639
		c-6.418,6.479-12.024,13.832-16.158,21.974c-3.602,7.17-6.021,15.154-5.554,23.249c0.149,3.391,0.948,6.727,2.107,9.913
		c-0.458-0.341-1.257-0.43-1.364-1.065c-2.462-8.128-3.078-16.733-2.466-25.169l-0.252-0.238c-0.098,0.131-0.294,0.392-0.392,0.523
		c-0.033,5.802-0.29,11.65,0.789,17.387c0.346,2.602,1.168,5.101,1.658,7.675c-1.845-2.359-2.906-5.237-4.265-7.89
		c-2.784-6.045-4.942-12.412-6.063-18.984c-0.705-4.433-1.168-9.002-0.322-13.444c-0.15-0.005-0.453-0.005-0.607-0.009
		c-1.28,9.207,0.939,18.536,4.13,27.145c1.565,4.167,3.527,8.17,5.648,12.085c-1.481-1.149-3.518-1.901-4.232-3.774
		c-0.444-0.089-0.883-0.173-1.313-0.248c-6.699-5.951-12.407-12.958-17.13-20.563c-1.677-2.831-3.616-5.596-4.391-8.843
		c-2.27-8.166-2.149-17.176,1.196-25.034C735.708,214.742,738.754,210.813,742.584,207.88 M740.65,229.284
		c0.547,15.677,6.133,30.803,13.869,44.312c0.154-0.206,0.318-0.402,0.49-0.598c-3.172-4.98-5.606-10.403-7.773-15.887
		c-3.312-8.838-5.755-18.153-5.998-27.626C741.089,229.433,740.8,229.335,740.65,229.284 M762.096,234.829
		c-1.682,5.713-2.709,11.604-3.27,17.532l0.252,0.234l0.177,0.019c0.528-2.35,0.547-4.825,1.037-7.203
		c0.649-4.531,1.99-8.908,3.144-13.318C762.568,232.75,762.395,233.857,762.096,234.829z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M635.681,207.151c0.668-0.575,1.336-1.616,2.368-1.285
		c2.672,0.444,5.339,0.953,8.03,1.313c1.299,0.21,2.733,0.192,3.821,1.056c-0.229-0.093-0.687-0.29-0.916-0.383
		C644.538,207.987,640.119,207.31,635.681,207.151z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M789.709,221.749c7.063-4.153,15.915-4.19,23.492-1.462
		c5.83,2.097,11.543,5.246,15.457,10.16c1.537,1.691,3.588,2.793,5.699,3.611c-3.354,2.691-4.69,6.974-6.077,10.875
		c-1.714,4.802-2.775,10.039-6.003,14.131c-4.718,5.886-12.416,8.376-19.699,8.974c-3.746,0.248-7.568-0.075-11.206,1.042
		c-7.642,2.083-14.019,7.68-17.985,14.434c-0.248,0.075-0.743,0.224-0.99,0.299c0.009,0.406,0.023,1.229,0.028,1.64
		c-0.976-0.528-1.85-1.21-2.756-1.822c-2.368-7.67-0.206-15.878,3.34-22.815c3.891-7.572,9.235-14.332,15.289-20.292
		c0.336-0.336,0.565-0.747,0.687-1.219c-2.546,1.925-4.587,4.433-6.685,6.815c-5.433,6.451-10.263,13.64-12.785,21.754
		c-1.513,4.956-2.041,10.375-0.495,15.397c-0.775-0.771-2.807-1.14-1.967-2.714c-0.299,0.36-0.589,0.719-0.864,1.084
		c-2.915-5.82-3.84-12.547-2.887-18.97c1.789-11.678,8.189-22.072,15.598-31.046C782.062,227.901,785.383,224.15,789.709,221.749
		 M778.325,242.139c-2.98,4.508-5.638,9.231-7.843,14.168c-3.373,7.549-5.75,15.892-4.419,24.211
		c0.178,0.019,0.528,0.056,0.705,0.075c-0.71-3.289-0.631-6.703-0.149-10.02c1.63-9.464,5.979-18.223,11.02-26.309
		c0.701-1.238,1.784-2.294,2.135-3.704C779.194,241,778.703,241.527,778.325,242.139 M796.057,252.033
		c-5.807,1.985-11.188,5.363-15.233,10.001c-5.026,5.648-7.68,13.089-8.301,20.554c0.14,0.308,0.313,0.612,0.514,0.902
		c0.439-6.848,2.565-13.706,6.717-19.232c4.685-6.32,11.79-10.59,19.349-12.542c1.163-0.346,2.485-0.341,3.494-1.102
		C800.336,250.571,798.178,251.361,796.057,252.033z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M740.65,229.284c0.149,0.051,0.439,0.149,0.589,0.201
		c0.243,9.473,2.686,18.788,5.998,27.626c2.167,5.484,4.601,10.908,7.773,15.887c-0.173,0.196-0.336,0.392-0.49,0.598
		C746.784,260.087,741.197,244.961,740.65,229.284z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M762.096,234.829c0.299-0.972,0.472-2.079,1.341-2.737
		c-1.154,4.41-2.495,8.787-3.144,13.318c-0.491,2.378-0.509,4.854-1.037,7.203l-0.177-0.019l-0.252-0.234
		C759.387,246.432,760.415,240.542,762.096,234.829z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M778.325,242.139c0.378-0.612,0.869-1.14,1.448-1.579
		c-0.35,1.411-1.434,2.466-2.135,3.704c-5.04,8.086-9.389,16.845-11.02,26.309c-0.481,3.317-0.561,6.731,0.149,10.02
		c-0.177-0.019-0.528-0.056-0.705-0.075c-1.331-8.32,1.046-16.663,4.419-24.211C772.686,251.37,775.344,246.647,778.325,242.139z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M720.237,244.358c3.214-0.832,6.456,0.612,9.221,2.153
		c0.948,0.621,2.097,1.098,2.728,2.097c6.302,11.431,14.444,22.114,25.094,29.822c-0.785,0.107-1.696-0.238-2.382,0.154
		c-0.411,0.509-0.962,0.71-1.658,0.598c0.037,0.252,0.103,0.761,0.14,1.014c-3.064,0.547-6.091-0.789-8.81-2.051
		c-4.886-2.448-9.114-6.035-12.902-9.936c-0.972-0.93-1.71-2.149-2.952-2.737c1.345,2.312,3.471,4.05,5.367,5.905
		c3.405,3.097,7.133,5.928,11.351,7.824c2.168,0.916,4.461,1.761,6.858,1.626c-0.439,1.154-0.733,2.364-0.911,3.588
		c-8.039-0.047-15.513-3.863-21.759-8.661c-1.476-1.065-2.7-2.443-4.246-3.401c0.322,1.187,1.481,1.789,2.317,2.583
		c6.675,5.522,14.831,9.955,23.688,10.071c0.07,0.35,0.21,1.051,0.28,1.401c-7.016-0.785-14.047,0.388-20.979,1.397
		c-0.958,0.22-2.116,0.093-2.761,0.972c7.843-1.088,15.742-2.621,23.698-1.854c0.556,1.009,1.187,1.981,1.869,2.924
		c-10.959,0.556-21.441,4.185-32.022,6.769c-2.466,0.71-4.498,2.803-7.203,2.546c-3.732-0.103-6.918-2.635-8.838-5.685
		c-1.813-2.859-3.321-6.344-2.382-9.772c0.603-2.597,2.438-4.653,4.489-6.246c2.906-3.004,4.134-7.18,5.218-11.118
		c1.205-4.545,1.626-9.24,2.242-13.888C715.528,249.263,716.822,245.428,720.237,244.358 M736.591,261.198
		c1.509,2.504,3.499,4.681,5.367,6.923c3.116,3.433,6.381,6.825,10.342,9.296c0.495,0.257,0.962,0.757,1.593,0.621
		c0.014-0.089,0.037-0.271,0.051-0.36c-6.638-3.723-11.604-9.73-16.182-15.682C737.474,261.577,737.081,261.311,736.591,261.198
		 M732.943,266.038c0.117,0.402,0.341,0.729,0.682,0.976c6.297,4.125,12.734,8.039,18.984,12.239
		c0.187-0.21,0.383-0.416,0.589-0.617c-2.06-0.827-3.77-2.289-5.676-3.401C742.645,272.199,737.904,268.929,732.943,266.038z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M796.057,252.033c2.121-0.673,4.279-1.462,6.54-1.42
		c-1.009,0.761-2.331,0.757-3.494,1.102c-7.558,1.953-14.663,6.222-19.349,12.542c-4.153,5.526-6.278,12.384-6.717,19.232
		c-0.201-0.29-0.374-0.593-0.514-0.902c0.621-7.465,3.275-14.906,8.301-20.554C784.869,257.396,790.251,254.019,796.057,252.033z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1165.349,251.431c5.04,27.397,5.834,55.78,0.584,83.22
		c-4.382,23.095-13.192,45.354-25.954,65.1c-12.202,18.933-27.86,35.619-45.915,49.077c-5.391,3.957-10.936,7.759-16.849,10.889
		c3.429-3.228,7.166-6.143,10.543-9.445c16.999-15.826,31.784-34.054,43.621-54.047c12.127-20.442,21.166-42.691,26.949-65.74
		C1164.732,304.721,1167.554,277.926,1165.349,251.431z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M736.591,261.198c0.49,0.112,0.883,0.378,1.172,0.799
		c4.578,5.951,9.544,11.959,16.182,15.682c-0.014,0.089-0.037,0.271-0.051,0.36c-0.631,0.135-1.098-0.364-1.593-0.621
		c-3.961-2.471-7.227-5.863-10.342-9.296C740.09,265.879,738.1,263.702,736.591,261.198z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M732.943,266.038c4.961,2.892,9.702,6.161,14.579,9.198
		c1.906,1.112,3.616,2.574,5.676,3.401c-0.206,0.201-0.402,0.406-0.589,0.617c-6.25-4.2-12.687-8.114-18.984-12.239
		C733.284,266.767,733.06,266.44,732.943,266.038z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M791.862,270.999c1.798-0.607,3.957-1.116,5.68-0.014
		c10.866,8.049,21.455,16.56,33.134,23.417c-9.548-1.999-19.923-1.747-28.771,2.723c3.34,7.404,8.128,14.561,15.214,18.83
		c0.364,0.238,0.719,0.509,1.06,0.803c-2.069,0.285-4.158-0.084-6.222,0.112c-8.539,0.556-17.214-0.177-25.375-2.807
		c-3.452-1.752-6.264-4.606-8.553-7.68c-2.621-4.386-5.096-9.09-5.671-14.238c-0.369-2.555,1.051-4.835,2.228-6.965
		c1.476-2.761,3.84-4.882,6.119-6.965C784.042,275.297,787.663,272.545,791.862,270.999z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M754.636,281.122c1.415-1.275,3.503-0.836,5.162-0.36
		c4.214,1.364,7.955,3.826,11.547,6.362c-0.565,1.369-0.916,2.803-1.065,4.274c-4.844,0.224-10.057,0.178-14.388-2.27
		C753.076,287.605,752.478,283.42,754.636,281.122z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M747.499,292.366c3.12-0.5,6.302-0.897,9.455-0.458
		c-9.291,2.065-18.4,5.61-26.136,11.239c-5.447,4.05-10.361,9.501-11.889,16.289c0.285-0.145,0.803-0.098,0.747-0.603
		c1.859-6.951,7.217-12.332,12.991-16.303c5.255-3.555,11.113-6.143,17.13-8.109c2.836-0.799,5.62-1.939,8.558-2.289
		c-2.588,1.532-5.433,2.611-7.899,4.363c-8.88,5.769-16.06,13.846-21.768,22.693c-1.654,2.756-3.541,5.447-4.597,8.497
		c0.378-0.229,0.682-0.528,0.911-0.906c3.774-7.409,8.623-14.28,14.369-20.292c5.498-5.764,12.029-10.599,19.349-13.79
		c0.341-0.047,1.023-0.131,1.369-0.177c-2.443,1.719-5.241,2.896-7.558,4.821c-9.959,7.535-16.2,19.078-19.25,31.018
		c-0.547,2.434-1.303,4.877-1.201,7.399c0.775-1.088,0.64-2.494,0.967-3.732c1.817-9.034,5.367-17.816,10.908-25.225
		c4.396-5.984,10.356-10.693,17.022-13.925c0.752,0.117,1.518,0.238,2.289,0.364c-7.507,4.774-13.589,11.744-17.289,19.834
		c-3.788,8.156-5.026,17.321-4.372,26.244c0.107,2.471,0.92,5.12-0.327,7.441c-1.584,3.27-5.395,4.629-8.81,4.797
		c-3.826,0.35-7.409-1.532-11.221-1.317c-3.247,0.019-6.39,1.476-8.642,3.784c-2.672,2.681-4.256,6.203-5.531,9.712
		c-0.206-0.49-0.299-0.995-0.28-1.509c0.35-5.545-0.5-11.141-2.322-16.382c-1.504-4.508-3.7-8.833-4.321-13.594
		c-0.64-4.592,0.458-9.231,2.139-13.491c2.868-7.353,8.1-13.869,14.934-17.882c2.032-0.649,3.924-1.626,5.849-2.532
		C731.102,295.944,739.226,293.772,747.499,292.366z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M765.969,294.799c0.402-0.719,1.065-1.233,1.78-1.621
		c-0.962,2.625-2.84,4.779-3.854,7.385c-3.77,8.801-3.812,18.671-2.761,28.037c0.701,5.839,1.971,11.608,3.676,17.237
		c0.262,0.705,0.322,1.686,1.201,1.92c-3.116-9.824-5.153-20.101-4.882-30.443c0.159-6.844,1.495-13.832,4.952-19.811
		c-0.051,0.458-0.159,1.373-0.215,1.831c0.196-0.037,0.584-0.112,0.78-0.145c-0.327-2.177,0.902-4.209,2.27-5.797
		c0.36-0.023,1.07-0.061,1.429-0.084c0.789,7.1,4.812,13.467,9.786,18.424c2.097,1.99,4.466,3.774,7.18,4.844
		c1.537,0.621,3.251,0.724,4.709,1.574c2.943,1.668,4.905,4.615,6.283,7.628c3.176,7.315,3.461,16.069-0.145,23.291
		c-2.868,6.484-1.383,14.145,2.56,19.848c-6.643-1.602-13.453-0.327-20.091,0.537c-5.349,0.757-10.94,0.523-15.99-1.513
		c-10.735-4.064-18.358-14.238-20.759-25.272c-1.509-11.155,0.112-22.988,5.919-32.76c3.788-6.573,9.427-11.889,15.77-15.971
		C765.689,294.225,765.824,294.51,765.969,294.799 M765.292,294.477c-5.153,5.391-9.74,11.505-12.318,18.569
		c-3.611,9.455-3.251,20.344,1.084,29.499c0.505,0.976,0.874,2.153,1.943,2.686c-1.257-2.709-2.681-5.363-3.433-8.278
		c-2.069-6.937-1.999-14.444,0.149-21.353c2.364-7.787,7.1-14.654,12.762-20.423l0.313,0.476
		C765.619,295.257,765.451,294.865,765.292,294.477 M765.637,299.251c-0.117,3.373-0.262,6.783,0.439,10.118
		c1.934,9.955,7.152,18.947,13.234,26.935c0.78,0.878,1.35,2.111,2.569,2.457c-1.14-1.766-2.597-3.298-3.779-5.031
		c-4.956-6.932-9.188-14.575-11.146-22.927c-0.696-3.48-1.322-7.096-0.635-10.627C766.086,299.863,765.857,299.555,765.637,299.251z
		"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M765.292,294.477c0.159,0.388,0.327,0.78,0.5,1.177l-0.313-0.476
		c-5.662,5.769-10.398,12.636-12.762,20.423c-2.149,6.909-2.219,14.416-0.149,21.353c0.752,2.915,2.177,5.568,3.433,8.278
		c-1.07-0.533-1.439-1.71-1.943-2.686c-4.335-9.156-4.695-20.045-1.084-29.499C755.552,305.982,760.139,299.868,765.292,294.477z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M765.637,299.251c0.22,0.304,0.448,0.612,0.682,0.925
		c-0.687,3.532-0.061,7.147,0.635,10.627c1.957,8.352,6.19,15.995,11.146,22.927c1.182,1.733,2.639,3.265,3.779,5.031
		c-1.219-0.346-1.789-1.579-2.569-2.457c-6.082-7.988-11.3-16.98-13.234-26.935C765.376,306.034,765.521,302.624,765.637,299.251z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M121.951,343.988c4.073-3.788,9.329-6.568,14.944-6.946
		c5.783-0.434,11.622,2.182,15.397,6.526c0.322,0.444,0.635,0.906,0.925,1.387c0.659,1.098,0.687,2.429,0.93,3.667
		c0.752,5.881,0.369,12.374-3.251,17.326c-0.696,0.818-1.294,1.929-2.345,2.298c-2.803-0.243-5.778-1.411-7.269-3.929
		c-1.173-1.873-1.196-4.167-0.995-6.292c-1.803,2.125-3.901,4.013-6.26,5.493c-1.626,1.014-3.62,1.644-5.521,1.126
		c-2.359-0.953-3.667-3.737-3.018-6.176c0.827-3.275,3.265-5.825,5.746-7.974c-1.64-1.574-3.849-2.611-6.091-3.013
		c-1.775-0.065-3.48,0.715-5.274,0.453C119.653,346.31,120.984,345.105,121.951,343.988z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M881.907,354.401c-0.042,0.098-0.126,0.299-0.168,0.397
		c-6.577,4.746-12.332,10.506-18.153,16.125c-9.399,9.301-18.484,18.924-27.066,28.986c-6.563,7.343-14.21,13.626-22.025,19.587
		c-14.687,11.001-30.317,21.021-47.47,27.729c-17.98,7.086-37.978,10.445-57.07,6.297c-8.88-1.906-17.461-5.414-24.917-10.627
		c-0.93-0.621-1.794-1.331-2.551-2.135c10.543,5.886,22.969,7.077,34.839,6.671c19.75-0.93,39.104-6.334,57.135-14.29
		c17.088-7.652,33.157-17.859,46.615-30.938c12.328-11.706,25.113-22.969,38.711-33.19
		C866.874,363.729,874.157,358.666,881.907,354.401z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M145.808,404.281c3.863-3.116,9.02-4.583,13.944-3.868
		c2.06,0.304,4.204,0.631,5.979,1.798c0.383,0.22,0.761,0.453,1.135,0.696c2.985,3.363,5.101,7.521,6.269,11.861
		c0.85,3.405,0.883,7.11-0.406,10.412c-0.178,0.659-0.962,0.691-1.495,0.902c-2.593,0.794-5.643,0.561-7.773-1.233
		c-1.495-1.14-2.158-2.952-3.064-4.527c-1.056,3.293-2.266,6.722-4.751,9.235c-1.336,1.355-3.48,2.037-5.241,1.093
		c-2.153-0.836-3.275-3.167-3.317-5.367c-0.033-2.868,0.846-5.657,2.027-8.236c-1.892-0.392-3.849-0.701-5.764-0.285
		c-2.158,0.364-3.592,2.214-5.587,2.985c-1.023-1.294-0.29-2.948,0.103-4.34C139.45,411.083,142.211,407.168,145.808,404.281z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1021.584,484.871c4.358-1.49,9.114-1.359,13.561-0.318
		c5.232,1.205,10.51,2.523,15.925,2.438c3.172,0.056,6.274-0.719,9.417-0.92c-1.878,1.257-3.943,2.233-5.676,3.704
		c-2.658,2.111-4.919,4.952-5.61,8.343c-0.691,3.078,0.005,6.283,1.21,9.146c1.443,3.588,2.172,7.731,0.808,11.454
		c-0.976,2.779-3.69,4.933-6.68,4.924c-7.371,0.159-14.776,1.42-21.586,4.298c-11.165,4.611-20.395,13.724-25.038,24.879
		c-0.248,0.696-0.677,1.42-0.519,2.191c0.444,0.355,0.916,0.701,1.411,1.037c-0.252,0.248-0.761,0.747-1.014,1
		c0.271-0.005,0.808-0.014,1.074-0.019l-0.621,1.06c1.537-0.631,2.14-2.476,3.704-3.106c3.826-1.682,8.058-2.359,12.23-2.284
		c7.932,0.355,15.612,2.854,22.763,6.194c3.167,1.383,6.054,3.307,9.132,4.849c-0.416-0.958-1.401-1.341-2.214-1.855
		c-7.054-4.041-14.626-7.339-22.628-8.918c-5.97-1.168-12.314-1.252-18.069,0.93c4.386-3.788,10.071-5.76,15.644-7.091
		c9.651-2.135,19.671-2.004,29.429-0.705c1.551,0.173,3.093,0.635,4.676,0.458c-0.981-0.598-2.163-0.659-3.251-0.845
		c-9.338-1.327-18.9-1.626-28.224-0.023c-6.498,1.159-12.996,3.307-18.284,7.362c-1.387,1.154-2.765,2.345-4.405,3.12
		c0.406-0.794,0.944-1.485,1.616-2.074c-0.276-0.224-0.537-0.439-0.789-0.645c2.672-5.521,6.049-10.772,10.595-14.948
		c8.18-7.852,19.386-12.01,30.597-12.753c4.774-0.631,9.576,0.476,14.061,2.074c8.95,3.172,16.616,10.244,19.783,19.288
		c3.508,10.071-0.117,21.199,3.961,31.167c-6.175-3.195-14.042-3.564-20.059,0.196c-6.647,4.513-15.341,5.512-23.002,3.359
		c-2.868-0.855-5.69-2.186-7.778-4.368c-1.831-1.826-2.121-4.555-3.681-6.559c-3.471-4.779-8.633-8.081-14.037-10.286
		c-4.555-1.733-9.749-2.845-14.472-1.149c-1.883,0.593-3.195,2.387-5.208,2.593c-7.451,1.411-14.635,4.961-19.774,10.613
		c-5.83,6.32-8.833,14.916-9.128,23.436c0.168-0.005,0.495-0.019,0.663-0.023c0.037-6.054,1.766-12.071,4.732-17.331
		c3.536-6.288,9.333-11.188,16.009-13.865c2.485-1.074,5.124-1.686,7.712-2.466c-1.448,1.616-3.204,2.934-4.615,4.592
		c-3.896,4.358-6.755,9.777-7.521,15.616c-0.673,4.237,0.434,8.441,0.547,12.669c0.262,7.596-1.714,15.976-7.811,21.021
		c-5.61,4.391-12.795,6.147-18.428,10.506c-1.238,0.939-2.284,2.111-3.083,3.438c-0.747-1.35-1.574-2.672-2.691-3.742
		c-1.607-1.724-3.887-2.569-5.55-4.223c-4.036-3.77-7.245-8.418-9.436-13.481c-1.971-4.695-3.013-9.894-2.275-14.972
		c0.626-4.966,2.845-9.651,5.956-13.538c4.508-5.713,9.66-10.926,15.434-15.364c7.166-5.465,15.607-9.814,24.749-10.361
		c3.943-0.364,7.81,0.631,11.646,1.355c1.387-0.565,0.262-1.784-0.612-2.083c0.089-2.046-2.205-3.088-2.625-4.98
		c-1.78-1.074-2.901-2.896-4.48-4.204c-8.703-8.021-19.372-13.561-30.322-17.84c-1.967-1.854-3.56-4.125-4.667-6.591
		c-0.902-2.018-1.257-4.503-0.005-6.456c1.612-2.508,4.541-3.695,7.292-4.503c7.371-1.934,15.088-3.891,21.18-8.707
		c1.939-1.383,2.611-3.831,4.41-5.335c2.116-1.99,5.134-2.957,8.011-2.476c3.714,0.673,7.455,2.298,9.88,5.293
		c1.827,2.312,2.784,5.671,1.383,8.422c-1.257,2.807-1.088,5.9-1.593,8.866c-0.897,6.535-1.607,13.108-1.869,19.699
		c0.07,3.429-0.374,7.04,0.92,10.305c1.696-0.747,1.668,1.014,2.018,2.079c0.967-8.558,2.443-17.172,5.919-25.104
		c2.733-6.236,6.699-12.239,12.543-15.943c1.13-0.818,2.602-1.093,3.616-2.083c-2.887,0.397-5.227,2.448-7.409,4.237
		c-7.1,6.544-10.847,15.868-13.019,25.094c-0.677,3.373-1.574,6.759-1.556,10.221c-0.192,0.005-0.57,0.014-0.757,0.019
		c-0.99-8.072,0.098-16.205,0.967-24.239c0.444-3.064,0.673-6.171,1.369-9.189c0.747-1.784,1.116-3.676,1.546-5.55
		C1003.595,495.466,1011.943,488.15,1021.584,484.871 M1019.706,513.464c-10.697,9.146-19.904,20.881-23.258,34.787
		c1.009-0.397,0.864-1.654,1.252-2.495c4.321-14.061,14.308-25.697,25.767-34.587c1.186-1.079,2.868-1.672,3.69-3.111
		C1024.392,509.414,1022.107,511.563,1019.706,513.464 M1018.394,522.578c-9.978,6.255-18.232,15.826-21.287,27.36
		c0.766-0.285,0.892-1.13,1.149-1.803c1.383-4.302,3.508-8.362,6.18-12.001c7.012-9.487,17.41-16.051,28.453-19.877
		c1.088-0.476,2.532-0.462,3.265-1.518C1029.843,516.29,1023.892,519.14,1018.394,522.578 M972.582,516.846
		c0.28,0.99,1.252,1.495,1.953,2.177c6.848,6.012,12.524,13.967,13.738,23.198c0.612-0.757,0.14-1.738,0.065-2.569
		c-1.308-6.839-5.339-12.814-10.034-17.812C976.52,520.051,974.768,518.145,972.582,516.846 M988.642,517.79
		c-0.126,6.825-0.392,13.738,0.747,20.507c0.36,1.448,0.444,3.209,1.724,4.204c-2.672-8.109-1.953-16.789-1.981-25.188
		L988.642,517.79 M966.075,521.406c1.532,1.495,3.466,2.495,5.101,3.877c5.97,4.727,11.711,10.623,13.379,18.316
		c0.71-0.901-0.131-2.032-0.29-2.99c-2.462-6.974-8.119-12.197-13.827-16.616C969.013,523.097,967.789,521.672,966.075,521.406
		 M967.49,525.871c4.358,4.872,8.432,9.992,12.846,14.813c-0.243,0.224-0.477,0.453-0.691,0.691
		c-4.676-4.947-10.609-8.549-16.583-11.716c0.033,0.163,0.098,0.495,0.126,0.663c6.437,3.55,12.879,7.511,17.7,13.159
		c1.308,1.317,1.7,3.298,3.12,4.513c-0.649-2.322-2.074-4.368-3.784-6.031c0.224-0.215,0.462-0.425,0.71-0.626
		c0.813,1.154,1.644,2.406,2.91,3.125c-0.336-1.018-1.13-1.752-1.789-2.536c-4.793-5.545-9.52-11.15-14.327-16.681
		C967.668,525.404,967.551,525.717,967.49,525.871 M1015.301,539.442c-5.83,3.377-10.651,8.194-14.878,13.383
		c0.173,0.182,0.355,0.369,0.556,0.565c1.084-1.883,2.653-3.41,4.111-4.998c5.176-5.475,11.589-9.922,18.835-12.145
		c8.077-2.518,16.994-2.107,24.847,1.032c-0.192-0.556-0.663-0.874-1.182-1.023C1037.07,532.365,1024.887,533.672,1015.301,539.442
		 M969.354,560.308c-6.474,3.163-12.402,7.334-17.887,11.982c-0.976,0.99-2.396,1.663-2.859,3.05c2.504-1.738,4.62-3.985,7.11-5.76
		c6.521-4.952,13.612-9.427,21.554-11.692c2.406-0.589,4.886-1.299,7.39-0.897c-0.355-0.271-0.701-0.533-1.032-0.785
		C978.636,556.548,973.829,558.146,969.354,560.308 M985.167,557.067c1.495-0.561,3.032,0.163,4.541,0.308
		c-1.074-1.443-3.275-0.794-4.797-1.163C984.975,556.427,985.101,556.856,985.167,557.067 M970.438,563.326
		c-7.315,4.386-13.495,10.44-18.863,17.022c-1.004,1.406-2.42,2.611-2.915,4.312c1.593-1.28,2.546-3.153,3.924-4.643
		c5.11-6.129,10.926-11.79,17.76-15.971c5.895-3.569,12.935-5.989,19.872-4.601c0.103-0.112,0.304-0.332,0.402-0.444
		C983.653,557.375,976.417,559.766,970.438,563.326z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1019.706,513.464c2.401-1.901,4.685-4.05,7.451-5.405
		c-0.822,1.439-2.504,2.032-3.69,3.111c-11.459,8.889-21.446,20.526-25.767,34.587c-0.388,0.841-0.243,2.097-1.252,2.495
		C999.802,534.345,1009.009,522.611,1019.706,513.464z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1018.394,522.578c5.498-3.438,11.449-6.288,17.76-7.839
		c-0.734,1.056-2.177,1.042-3.265,1.518c-11.043,3.826-21.442,10.389-28.453,19.877c-2.672,3.639-4.797,7.698-6.18,12.001
		c-0.257,0.673-0.383,1.518-1.149,1.803C1000.161,538.404,1008.416,528.833,1018.394,522.578z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M972.582,516.846c2.186,1.299,3.938,3.205,5.722,4.994
		c4.695,4.998,8.726,10.973,10.034,17.812c0.075,0.831,0.547,1.812-0.065,2.569c-1.215-9.231-6.89-17.186-13.738-23.198
		C973.834,518.341,972.862,517.837,972.582,516.846z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M988.642,517.79l0.491-0.477c0.028,8.399-0.691,17.078,1.981,25.188
		c-1.28-0.995-1.364-2.756-1.724-4.204C988.25,531.528,988.516,524.615,988.642,517.79z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M966.075,521.406c1.714,0.266,2.938,1.691,4.363,2.588
		c5.708,4.419,11.365,9.642,13.827,16.616c0.159,0.958,1,2.088,0.29,2.99c-1.668-7.694-7.409-13.589-13.379-18.316
		C969.541,523.9,967.607,522.9,966.075,521.406z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M967.49,525.871c0.061-0.154,0.178-0.467,0.238-0.626
		c4.807,5.531,9.534,11.136,14.327,16.681c0.659,0.785,1.453,1.518,1.789,2.536c-1.266-0.719-2.097-1.971-2.91-3.125
		c-0.248,0.201-0.486,0.411-0.71,0.626c1.71,1.663,3.134,3.709,3.784,6.031c-1.42-1.215-1.813-3.195-3.12-4.513
		c-4.821-5.648-11.263-9.609-17.7-13.159c-0.028-0.168-0.094-0.5-0.126-0.663c5.975,3.167,11.907,6.769,16.583,11.716
		c0.215-0.238,0.448-0.467,0.691-0.691C975.922,535.863,971.849,530.744,967.49,525.871z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M916.087,536.48c5.989-5.643,14.243-8.399,22.371-8.717
		c4.354-0.093,8.871,0.135,12.907,1.943c9.791,3.98,19.199,9.212,27.056,16.345c-14.191-5.947-29.724-9.259-45.139-8.081
		c-0.598-0.023-1.182,0.065-1.434,0.673c15.714-1.448,31.569,1.803,46.078,7.792c2.051,0.533,3.153,2.49,4.611,3.849
		c-11.874-4.442-24.87-7.273-37.52-4.956c-1.014,0.341-2.508,0.168-2.98,1.322c7.806-2.228,16.102-1.859,24.025-0.453
		c5.073,0.916,10.039,2.364,14.892,4.106c0.925,0.392,2.051,0.458,2.7,1.359c-14.663-2.093-29.7,0.944-43.149,6.881
		c-0.925,0.481-2.121,0.743-2.597,1.775c14.177-6.652,30.172-10.384,45.83-8.067c0.626,0.005,0.743,0.827,1.098,1.238
		c-9.165-2.233-18.858,0.149-26.94,4.685c-9.763,5.503-18.059,13.402-24.777,22.315c-7.754-0.631-16.06-2.924-21.311-9.039
		c-3.807-4.092-8.899-7.096-14.444-8.016c4.984-6.082,9.329-12.669,13.14-19.545C911.976,541.156,913.821,538.61,916.087,536.48z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1015.301,539.442c9.586-5.769,21.768-7.077,32.288-3.186
		c0.519,0.149,0.99,0.467,1.182,1.023c-7.853-3.139-16.77-3.55-24.847-1.032c-7.245,2.224-13.659,6.671-18.835,12.145
		c-1.458,1.588-3.027,3.116-4.111,4.998c-0.201-0.196-0.383-0.383-0.556-0.565C1004.651,547.635,1009.471,542.819,1015.301,539.442z
		"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M852.987,534.784c8.441,0.065,16.756,2.27,24.688,5.04
		c8.899,3.247,17.345,7.745,25.001,13.323c-1.897,2.588-3.714,5.237-5.746,7.722C883.687,550.064,868.79,541.282,852.987,534.784z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M969.354,560.308c4.475-2.163,9.282-3.76,14.276-4.101
		c0.332,0.252,0.677,0.514,1.032,0.785c-2.504-0.402-4.984,0.308-7.39,0.897c-7.941,2.266-15.032,6.741-21.554,11.692
		c-2.49,1.775-4.606,4.022-7.11,5.76c0.463-1.387,1.883-2.06,2.859-3.05C956.952,567.642,962.88,563.471,969.354,560.308z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M985.167,557.067c-0.065-0.21-0.192-0.64-0.257-0.855
		c1.523,0.369,3.723-0.28,4.797,1.163C988.198,557.23,986.661,556.506,985.167,557.067z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M970.438,563.326c5.979-3.56,13.215-5.951,20.18-4.326
		c-0.098,0.112-0.299,0.332-0.402,0.444c-6.937-1.387-13.977,1.032-19.872,4.601c-6.834,4.181-12.65,9.842-17.76,15.971
		c-1.378,1.49-2.331,3.363-3.924,4.643c0.495-1.7,1.911-2.906,2.915-4.312C956.942,573.766,963.122,567.712,970.438,563.326z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1127.479,574.593c5.082-4.783,9.763-10.099,13.145-16.242
		c-0.887,8.684-5.293,16.607-10.875,23.156c-6.18,7.278-13.771,13.234-21.764,18.391c-7.783,5.003-16.088,9.146-24.627,12.678
		c-10.851,4.83-21.395,10.338-31.653,16.317c0.042-3.588,0.121-7.175,0.126-10.758c8.885-4.956,18.559-8.217,27.696-12.655
		C1096.723,597.305,1113.493,587.65,1127.479,574.593z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M996.756,561.079c3.134-0.691,6.4-0.051,9.459,0.747
		c3.686,1.098,7.465,2.275,10.557,4.648c2.728,2.069,5.601,4.424,6.605,7.848c2.457,5.629,3.943,11.646,4.709,17.737
		c0.757,5.998,0.845,12.463,4.232,17.714c-4.671-3.807-9.319-7.815-14.85-10.328c-1.761-0.733-3.704-1.457-5.624-1.037
		c-2.074,0.673-3.12,2.849-3.522,4.844c-0.986,4.97-0.126,10.043,0.532,14.995c-3.078-4.162-6.633-7.937-10.151-11.72
		c-5.11-5.321-10.258-10.604-15.537-15.756c-1.229-1.233-2.836-2.429-2.985-4.307c-0.374-3.989,1.448-7.745,3.209-11.202
		C986.596,569.581,990.469,563.63,996.756,561.079z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M907.347,570.071c2.168,1.439,3.648,3.653,5.69,5.255
		c4.157,3.489,9.413,5.4,14.696,6.4c1.28,0.308,2.882,0.061,3.756,1.289c-1.929,3.195-3.195,6.787-3.672,10.496
		C921.683,585.122,914.816,577.293,907.347,570.071z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1028.063,634.896c2.607-2.368,5.344-4.587,8.142-6.736
		c-0.215,3.788-0.262,7.586-0.505,11.379c-9.436,7.843-17.396,17.415-23.614,27.986c-11.823,20.124-17.639,43.355-19.045,66.534
		c-0.22,4.242-0.299,8.488-0.121,12.729c-2.027-4.279-2.845-9.053-3.532-13.71c-1.598-12.972-0.229-26.183,2.962-38.809
		C998.129,671.565,1010.588,650.544,1028.063,634.896z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M950.286,634.522c0.504-2.027,1.098-4.106,2.775-5.503
		c3.975,12.809,6.872,25.935,8.89,39.192c1.056,7.236,2.224,14.49,2.443,21.81c0.794,0.78,0.126,1.929,0.374,2.906
		c0.262,1.962,0.514,3.929,0.453,5.919c-0.051,0.009-0.154,0.023-0.206,0.033c0.206-4.041-0.85-7.988-0.976-12.01
		C961.665,668.945,957.096,651.283,950.286,634.522z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M870.266,671.607c19.442-5.349,39.594-8.287,59.774-8.226
		c2.602,0.21,5.508-0.322,7.811,1.215c2.041,1.158,3.461,3.508,3.125,5.9c-0.388,2.761-1.897,5.213-3.569,7.39
		c-4.933,6.395-12.131,10.894-19.928,12.893c-4.699,0.962-9.156,2.784-13.659,4.382c-28.159,10.482-54.108,26.79-75.83,47.54
		c-20.101,19.325-37.151,42.107-48.554,67.632c-12.762,26.561-20.003,55.892-20.073,85.406c3.247,3.158,6.792,6.049,10.006,9.268
		c9.259,8.913,17.508,18.839,24.833,29.397c0.439,0.682,0.972,1.285,1.677,1.747c-2.121-5.101-4.839-9.959-6.559-15.229
		c-5.167-14.803-6.143-30.887-3.779-46.335c1.854-11.907,5.587-23.562,11.309-34.185c3.569-6.563,7.899-12.771,13.337-17.929
		c5.358-5.223,12.258-9.31,19.891-9.67c-3.036,6.175-4.526,12.991-5.171,19.811c-1.224,13.762,0.939,27.631,4.933,40.799
		c5.947,19.461,15.649,37.576,26.893,54.463c10.043,15.07,21.469,29.168,33.526,42.663c11.071,12.65,21.778,25.636,31.779,39.15
		c0.532,0.579,0.836,1.555,1.686,1.77c6.395-16.887,14.075-33.26,22.366-49.292c3.63-7.231,7.824-14.444,8.96-22.581
		c0.206-2.836,0.565-6.246-1.64-8.427c-2.205-2.205-3.373-5.279-3.541-8.357c-0.313-5.05,1.57-10.011,4.41-14.112
		c1.612-2.326,4.078-4.391,7.03-4.48c1.897-0.159,3.69,0.575,5.353,1.411c-0.019-6.362,0.084-12.743,1.187-19.026
		c-6.727,6.171-16.677,7.941-25.421,6.031c-4.129-0.766-7.582-3.681-11.851-3.859c-5.685-0.617-11.221,1.812-15.532,5.367
		c-1.317-0.92-0.304-2.359-0.065-3.555c1.78-6.423,0.841-13.154,0.159-19.671c-0.57-5.335-0.35-10.87,1.63-15.915
		c2.593-7.026,7.726-12.986,14.051-16.943c2.938-1.892,6.19-3.205,9.455-4.424c-2.714-1.481-4.676-4.129-5.353-7.133
		c-0.878-3.485-0.42-7.152,0.57-10.557c0.897-3.209,0.953-6.9-0.878-9.8c-2.004-3.219-5.4-5.223-8.791-6.685
		c-1.303-0.612-2.864-1.037-3.63-2.345c-0.369-1.56,1.602-2.009,2.751-2.069c3.345-0.07,6.717,0.304,10.057-0.168
		c6.157-0.631,11.758-3.419,17.686-4.952c6.133-1.616,12.659-0.43,18.344,2.172c6.587,2.812,12.23,7.563,16.396,13.36
		c0.878-6.171,6.582-10.319,12.258-11.828c4.33-1.21,9.156,0.649,11.884,4.148c1.07,1.443,2.462,2.639,4.073,3.457
		c4.634,2.41,9.796,3.536,14.911,4.424c3.844,0.617,7.946,0.85,11.342,2.976c2.182,1.294,3.826,3.611,3.938,6.199
		c0.252,3.508-1.458,6.722-3.135,9.674c8.427-2.121,17.578-1.883,25.641,1.542c4.844,2.06,9.198,5.344,12.346,9.586
		c4.634,6.479,9.544,12.823,15.285,18.368c0.836,0.934,2.298,1.556,2.555,2.868c-0.841,0.747-2.051,0.757-3.055,1.121
		c-5.115,1.514-9.483,5.031-12.543,9.347c-4.676,6.339-12.356,9.562-19.834,11.221c2.896,3.975,5.115,8.521,5.792,13.435
		c1.355,10.169-2.723,20.432-9.128,28.21c-1.808,2.401-4.76,3.714-6.227,6.4c-1.037,1.556-1.383,3.401-1.869,5.171
		c-0.439,1.215-1.649,0.173-1.658-0.668c-0.659-2.611-3.069-4.172-5.311-5.311c-5.54-2.803-11.753-4.017-17.158-7.105
		c-4.905-2.896-8.24-7.909-9.94-13.262c-1.369-4.223-1.878-8.679-1.827-13.098c-5.377,6.288-10.426,12.865-15.462,19.433
		c-3.312,4.545-6.867,8.988-9.263,14.107c1.794-0.276,3.504-0.99,5.339-1.051c4.442-0.322,9.02,1.219,12.328,4.214
		c-4.358,1.686-7.259,5.596-9.773,9.347c-3.433,5.676-7.937,11.076-14.079,13.907c-1.756,0.822-3.662,1.238-5.568,1.495
		c-3.882,3.433-7.782,6.834-11.744,10.174c-2.415,2.097-5.232,3.844-6.956,6.619c-1.579,2.167-2.41,4.737-3.648,7.091
		c-9.866,15.219-17.228,31.975-22.721,49.231c10.099,14.981,19.194,30.663,26.762,47.078c7.96,17.251,14.149,35.329,18.33,53.865
		c7.684,33.811,8.81,68.724,7.502,103.25c-0.444,10.66-0.925,21.325-1.995,31.947c-1.803,19.096-3.457,38.207-5.307,57.299
		c4.218,7.979,8.432,15.967,12.314,24.123c0.462,0.911,0.752,2.013,1.658,2.644c-0.948-12.828-2.793-25.604-2.789-38.487
		c-0.542-41.43,8.058-82.776,23.814-121.034c7.399-17.999,16.345-35.362,26.725-51.824c4.751-7.568,9.81-14.962,14.019-22.857
		c3.975-7.371,7.25-15.093,10.37-22.857c7.353-18.685,13.846-37.698,20.39-56.677c3.116-9.179,6.283-18.344,9.296-27.561
		c7.138-22.6,11.79-46.176,11.613-69.948c-0.224-19.344-3.588-39.057-12.594-56.35c-1.691-3.209-3.588-6.311-5.867-9.132
		c15.621,8.025,28.336,20.928,37.88,35.539c15.028,23.156,22.343,50.647,24.057,78.021c1.5,21.306-0.5,42.911-6.526,63.427
		c-5.199,18.027-13.112,35.161-22.324,51.469c-18.265,32.059-41,61.288-64.516,89.624c-22.179,26.982-40.122,57.756-50.572,91.184
		c-7.469,24.095-11.328,49.554-9.254,74.783c0.047,2.284,1.364,4.232,2.21,6.292c6.54,14.724,12.631,29.677,17.527,45.036
		c1.943,7.619,3.751,15.275,5.601,22.922c0.374,1.093,0.187,2.536,1.187,3.34c-0.346-4.699,0.332-9.389,0.869-14.042
		c2.205-17.027,8.123-33.849,18.76-47.465c10.744-14.005,26.038-24.029,42.523-30.004c19.876-7.194,41.36-9.422,62.381-7.843
		c10.025,0.49,20.152-2.14,28.673-7.46c11.412-7.105,20.045-18.083,25.711-30.163c-0.827,9.73-2.154,19.461-4.667,28.916
		c-2.312,8.521-5.62,16.91-10.898,24.053c-4.143,5.643-9.525,10.366-15.644,13.766c-8.74,4.9-18.597,7.339-28.411,8.955
		c-11.8,1.854-23.74,2.593-35.656,3.172c-4.914,0.341-9.866,0.262-14.733,1.107c-9.146,1.434-17.947,5.045-25.431,10.506
		c-11.795,8.563-20.176,21.03-26.047,34.218c-5.372,12.043-8.852,24.87-11.048,37.856c-0.411,1.56,0.285,3.093,0.612,4.611
		c2.695,11.375,5.792,22.665,7.764,34.199c4.209,24.043,5.559,48.502,5.405,72.887c-0.248,27.645-2.354,55.281-6.297,82.65
		c-0.467,3.004-0.967,6.129-2.527,8.792c-0.467,0.733-1.387,1.705-2.317,1.168c-1.486-0.785-1.668-2.826-1.369-4.279
		c1.906-5.522,2.635-11.342,3.382-17.102c1.528-13.103,2.2-26.286,2.784-39.459c0.5-13.552,0.841-27.108,0.822-40.669
		c0.294-10.305-0.579-20.596-1.691-30.831c-2.256-19.937-5.699-39.725-9.843-59.354c-0.355-3.312-2.485-6.026-4.363-8.633
		c-0.107,0.92,0.304,2.154-0.631,2.793c-1.056,0.556-2.252-0.014-3.191-0.518c-2.882-1.686-5.176-4.153-7.479-6.516
		c-13.159-13.682-25.692-27.963-37.553-42.78c-0.916-1.089-1.668-2.378-2.91-3.102c-17.975-10.146-38.277-15.971-58.84-17.396
		c-21.04-1.373-42.528,1.219-62.222,8.936c-4.741,1.892-9.366,4.111-13.659,6.876c-14.444,9.548-30.434,16.49-46.723,22.212
		c-9.59,3.326-19.335,6.208-29.196,8.609c-8.871,2.186-17.989,3.373-27.126,3.513c-10.352-0.056-20.951-1.808-30.093-6.881
		c-6.946-3.821-12.795-9.567-16.798-16.401c-4.414-7.469-6.82-16.032-7.427-24.66c5.704,5.685,13.696,8.441,21.6,9.076
		c11.907,0.976,23.777-1.303,35.311-4.05c20.685-5.166,40.729-12.519,60.97-19.157c19.779-6.713,39.954-12.505,60.657-15.528
		c10.968-1.546,22.137-2.555,33.18-1.219c21.007,2.06,41.467,8.609,60.078,18.508c-1.64-3.162-3.952-5.928-5.816-8.96
		c-11.216-16.957-21.395-34.671-29.406-53.379c-0.402-1.14-1.686-1.439-2.607-1.99c-17.961-9.347-37.076-16.125-55.911-23.445
		c-16.013-6.194-31.821-13.024-46.849-21.357c-15.336-8.525-30.237-18.27-42.397-31.046c-11.548-11.804-20.54-26.314-24.959-42.29
		c-3.447-12.323-4.157-25.384-2.205-38.02c1.191-7.624,3.163-15.191,6.488-22.179c0.92-2.069,2.396-3.826,4.12-5.279
		c1.046,1.088,0.476,2.691,0.607,4.045c0.238,8.675,2.438,17.284,6.376,25.02c3.606,7.086,8.479,13.477,14.005,19.171
		c12.701,13.159,27.981,23.403,43.032,33.647c22.282,14.752,43.742,30.719,64.488,47.559c6.409,5.097,12.627,10.445,19.068,15.5
		c-4.354-11.23-9.123-22.306-13.565-33.503c-0.57-1.359-0.743-2.91-1.584-4.12c-13.538-12.856-25.048-27.743-34.909-43.57
		c-10.015-15.798-15.392-34.456-15.341-53.155c0.135-21.273,6.162-42.575,17.49-60.606c-15.7-16.406-30.994-33.194-46.293-49.969
		c-26.365-25.515-49.605-54.659-66.151-87.522c-9.95-19.947-17.335-41.318-20.423-63.437c-0.178-0.659-0.005-1.523-0.705-1.859
		c-11.006-7.114-22.693-13.201-34.904-17.961c-3.518-1.962-7.11-4.242-11.253-4.442c-6.885-0.346-13.752-1.07-20.615-1.63
		c-7.301,3.98-16.167,2.55-23.544-0.458c-4.69-1.812-9.88-3.751-14.93-2.317c1.074-4.111,3.48-7.941,7.054-10.305
		c1.649-1.191,3.574-1.883,5.484-2.523c-2.097-1.677-4.765-2.373-7.213-3.359c-10.651-3.732-21.577-6.587-32.442-9.581
		c2.443,3.644,4.788,7.418,6.138,11.632c1.78,5.251,2.116,11.183-0.065,16.373c-2.831,6.596-8.268,11.725-10.88,18.433
		c-0.874,1.967-0.659,4.125,0.089,6.096c-0.229,1.018-1.364,0.775-1.812,0.103c-2.177-2.111-5.101-3.471-8.151-3.536
		c-3.63-0.033-7.124-1.196-10.445-2.56c-6.68-2.859-12.898-7.287-16.859-13.472c-3.144-4.826-4.634-10.646-4.377-16.382
		c-7.278,3.139-15.728,5.138-23.436,2.35c-5.372-1.714-11.393-1.658-16.583,0.64c-0.626,0.425-1.294,0.159-1.855-0.206
		c1.551-5.989,1.957-12.201,2.266-18.354c0.266-4.924-0.369-9.927,0.813-14.766c2.583-11.281,11.622-20.227,22.053-24.679
		c0.369-0.313,1.49-0.248,1.126-0.939c-3.219-1.378-6.573-3.214-8.222-6.456c-1.135-2.111-0.995-4.695,0.019-6.82
		c1.448-3.424,4.33-5.877,6.9-8.436c3.172-3.111,6.148-6.46,8.525-10.221c1.285-2.186,2.649-4.503,2.621-7.124
		c0.07-3.475,1.542-7.007,4.293-9.203c2.611-2.121,6.045-2.868,9.329-3.111c3.821-0.252,7.792,0.972,10.487,3.77
		c-0.126-9.366,3.228-18.695,9.259-25.856c2.079-2.471,4.62-4.568,7.497-6.045c4.129-2.177,8.763-3.046,13.131-4.606
		c4.597-1.565,8.899-3.975,12.622-7.086c1.308-1.042,2.709-2.182,4.428-2.364c1.63,0.514,1.037,2.35,0.35,3.41
		c-2.219,3.723-4.513,7.829-4.078,12.328c0.271,3.312,2.354,6.161,4.905,8.161c3.144,2.49,6.035,5.615,7.198,9.544
		c0.799,2.551,0.537,5.358-0.631,7.759c9.076-3.088,19.395-2.999,28.051,1.355c5.895,2.826,10.585,7.717,13.813,13.351
		c3.583,6.129,6.914,12.767,12.79,17.064c0.701,0.533,2.172,1.467,0.981,2.317c-4.475-0.098-9.123,0.542-12.977,2.976
		c-2.593,1.551-4.718,3.821-6.133,6.488c-3.377,6.717-9.693,11.683-16.714,14.164c-3.546,1.233-7.362,1.831-11.094,1.233
		c2.289,2.429,4.671,4.769,6.703,7.427c1.957,2.34,3.503,4.994,5.498,7.306c1.121-2.322,2.957-4.47,5.517-5.19
		c3.457-1.004,6.965,0.518,10.015,2.055c4.966,2.611,9.431,7.147,10.249,12.902c0.374,1.985-0.598,4.064,0.206,5.993
		c1.196,3.055,3.611,5.433,6.073,7.521c5.47,4.536,11.987,7.502,18.27,10.721c11.351,5.718,22.507,11.823,33.451,18.288
		c-0.341-16.42,1.752-32.886,6.255-48.675c4.073-14.434,10.127-28.262,17.503-41.299c13.351-23.88,30.849-45.545,52.029-62.927
		C814.644,693.119,841.701,679.488,870.266,671.607 M891.572,679.74c-15.644,3.966-30.616,10.37-44.602,18.391
		c-26.019,14.953-48.624,35.329-67.874,58.237c-7.189,8.633-14.145,17.503-20.241,26.954c-8.89,13.85-15.78,28.995-20.213,44.849
		c-4.326,15.289-6.227,31.195-6.255,47.064c2.345,1.705,4.746,3.34,7.143,4.98c1.355-30.088,10.529-59.592,25.272-85.78
		c6.274-11.197,13.608-21.764,21.259-32.059c17.12-22.773,37.076-43.7,60.611-59.919c13.15-9.002,27.397-16.611,42.659-21.353
		c6.255-1.869,12.627-3.541,19.152-4.045C902.858,677.236,897.103,678.404,891.572,679.74 M598.6,702.835
		c-3.975,3.018-8.535,5.19-13.285,6.671c-4.293,1.387-8.815,2.354-12.678,4.793c-3.915,2.434-6.839,6.148-9.128,10.085
		c-3.863,6.657-5.746,14.565-4.76,22.231c0.696,1.976,1.173,4.013,1.542,6.077c2.546,6.741,5.358,13.393,8.535,19.862
		c1.883,3.676,3.784,7.423,6.563,10.52c-0.28,0.518-0.986,1.654-1.644,0.906c-1.789-1.728-3.013-3.938-4.27-6.054
		c-4.106-7.404-7.311-15.261-10.487-23.095c-1.046-1.826-1.537-3.882-1.859-5.937c-0.201-2.415-2.004-4.372-3.971-5.596
		c-3.831-2.238-8.619-1.808-12.641-0.346c-3.756,1.387-6.194,5.311-6.175,9.263c0.061,2.523-1.079,4.858-2.27,7.021
		c-2.625,4.517-6.171,8.408-9.842,12.094c-2.686,2.56-5.718,5.218-6.633,8.974c-0.598,2.317,0.659,4.648,2.392,6.129
		c2.275,1.957,5.092,3.251,7.988,4.008c7.881-1.126,15.883-1.798,23.842-1.149c5.227,0.388,10.515,1.098,15.467,2.887
		c0.21-0.252,0.43-0.495,0.654-0.724c-1.658-0.504-2.99-1.756-4.671-2.228c-6.946-2.41-14.416-2.695-21.698-2.555
		c0.009-0.159,0.023-0.476,0.033-0.631c5.381-0.042,10.791,0.131,16.097,1.102c3.76,0.645,7.404,1.948,10.581,4.078
		c-0.117,0.28-0.35,0.841-0.467,1.121c0.313,0.019,0.944,0.056,1.257,0.075c1.476,1.233,3.84,1.243,4.849,3.018
		c0.831-0.201,1.855,0.075,1.826,1.13c-0.598,0.766-1.64,0.794-2.485,1.102c-4.643,1.257-8.96,3.653-12.552,6.848
		c-5.18,4.564-8.955,10.506-11.781,16.761c-3.284,7.367-5.414,15.229-6.573,23.202c-0.808,5.858,0.182,12.015,3.205,17.139
		c3.714,6.334,9.889,10.908,16.569,13.748c3.251,1.322,6.666,2.49,10.221,2.462c2.513,0.065,4.854,1.088,6.998,2.331
		c0.079-2.2,0.78-4.312,1.794-6.246c2.336-4.508,5.596-8.446,8.189-12.799c1.831-2.952,2.99-6.353,3.032-9.843
		c0.159-7.661-3.541-14.803-8.156-20.689c-6.358-8.123-6.815-19.783-2.747-29.014c-0.313-0.234-0.598-0.453-0.888-0.663
		c2.107-0.883,2.191-3.508,3.7-4.994c3.994-4.569,10.352-5.956,16.149-6.395c6.175-0.271,12.982,0.514,17.854,4.685
		c3.181,2.434,7.535,2.406,11.277,1.551c6.334-1.509,12.239-5.138,16.247-10.3c1.808-2.284,2.761-5.134,4.755-7.283
		c3.718-4.372,9.436-6.619,15.079-6.942c-1.35-1.187-2.658-2.424-3.812-3.802c-4.629-5.302-7.128-12.038-11.459-17.55
		c-2.817-3.62-6.573-6.465-10.739-8.362c-8.091-3.751-17.569-3.667-25.907-0.761c-2.228,0.701-4.326,1.761-6.236,3.106
		c-7.759,5.054-14.486,11.949-18.494,20.362c-3.709,7.596-5.017,16.242-4.172,24.623c0.14,0.084,0.425,0.252,0.565,0.332
		c0.042,0.36,0.117,1.084,0.159,1.443c1.154-0.378,1.313-2.172,1.7-3.237c0.402-0.168,0.813-0.318,1.238-0.453
		c2.256-2.7,5.152-4.802,8.231-6.474c7.437-3.952,15.892-5.517,24.221-6.105c3.074,0.009,6.175-0.486,9.235,0.079
		c-0.374,0.29-0.785,0.416-1.243,0.369c-8.095-0.229-16.275,0.565-24.043,2.924c-6.549,1.999-12.963,5.391-17.158,10.954
		c-1.07,1.359-1.084,3.172-1.616,4.765c-0.093-0.238-0.276-0.71-0.369-0.944c-0.182-0.005-0.542-0.014-0.724-0.019l0.262-0.766
		l-0.271-0.224c-0.841-0.112-1.911-0.308-1.859-1.392c-1.668-10.964,0.93-22.469,6.937-31.77c4.578-7.133,11.038-12.93,18.27-17.284
		c1.135-0.682,2.252-1.481,2.985-2.607c1.63-2.387,1.453-5.61,0.285-8.151c-1.35-3.092-3.835-5.503-6.451-7.549
		c-3.041-2.457-5.33-6.171-5.199-10.183c-0.145-4.587,2.111-8.768,4.494-12.529C600.987,700.682,599.894,701.906,598.6,702.835
		 M526.96,792.287c-3.947,0.906-7.6,2.807-10.982,4.994c-7.002,4.611-12.781,11.576-14.682,19.867
		c-1.107,4.307-0.565,8.777-0.752,13.169c-0.22,5.919-0.603,11.865-1.808,17.676c5.297-1.864,11.164-1.897,16.508-0.206
		c7.614,2.761,16.018,0.668,23.1-2.667c1.658-12.145,5.344-24.282,12.342-34.456c4.653-6.731,11.305-12.29,19.246-14.612
		c-0.336-0.154-1.009-0.458-1.345-0.612c-11.889,4.606-22.142,12.753-30.34,22.418c-2.121,2.312-3.718,5.05-5.82,7.362
		c0.243-1.584,1.532-2.695,2.378-3.98c7.698-10.057,17.424-18.774,28.887-24.31c1.401-0.752,3.05-1.056,4.279-2.107
		c-1.257-0.023-2.471,0.276-3.686,0.528c-10.197,1.817-20.33,5.082-28.948,10.954c-2.261,1.401-4.059,3.396-6.194,4.952
		c0.57-1.574,2.144-2.429,3.312-3.522c9.665-7.624,21.778-11.281,33.75-13.351c-1.943-0.523-3.919-1.616-5.975-1.004
		c-12.514,1.621-24.852,5.19-35.974,11.22c-2.747,1.392-5.213,3.251-7.871,4.788c0.542-1.285,1.915-1.798,2.976-2.565
		c12.211-7.684,26.281-12.15,40.552-13.939c-0.607-0.505-1.378-0.551-2.097-0.696C547.626,790.409,537.153,790.68,526.96,792.287
		 M954.345,791.432c-6.012,1.289-11.576,4.209-17.7,5.054c-3.915,0.747-7.899,0.397-11.842,0.378
		c3.765,1.883,7.913,3.578,10.534,7.082c2.663,3.275,3.181,7.834,2.097,11.828c-0.878,2.999-1.462,6.161-0.925,9.287
		c0.425,2.775,1.929,5.592,4.569,6.825c2.242,1.158,4.816,0.434,7.217,0.444c10.258-0.472,20.806,1.658,29.607,7.1
		c7.399,4.531,13.617,11.122,17.363,18.975c-0.495,0.448-1.032,0.859-1.537,1.294c0.238,0.229,0.705,0.682,0.944,0.911l-1.004,0.117
		c0.229,0.248,0.691,0.743,0.92,0.995c-1.668-0.476-2.537-2.303-4.246-2.733c-4.33-1.331-8.969-1.397-13.411-0.654
		c-9.067,1.644-17.41,5.984-24.945,11.146c-1.528,0.962-2.812,2.294-4.466,3.046c0.514-1.023,1.504-1.616,2.392-2.27
		c4.055-2.943,8.338-5.592,12.851-7.768c8.175-3.952,17.56-6.573,26.608-4.377c-4.877-3.163-10.655-4.634-16.368-5.344
		c-11.407-1.224-22.899,0.612-33.895,3.63c0.103-0.159,0.308-0.486,0.411-0.649c5.138-1.49,10.398-2.569,15.71-3.251
		c10.169-1.196,20.928-1.177,30.471,2.99c2.887,1.144,5.223,3.284,8.095,4.466c-5.69-6.773-12.968-12.454-21.329-15.523
		c-9.838-3.63-21.18-2.859-30.41,2.125c0.164-0.902,1.093-1.149,1.798-1.513c8.614-4.022,18.769-4.541,27.776-1.509
		c8.24,2.667,15.224,8.119,21.185,14.257c-6.759-12.711-20.068-21.427-34.306-23.104c-6.325-0.677-12.982-1.074-19.036,1.238
		c-5.783,2.041-11.16,5.423-15.21,10.062c-3.788,4.372-6.474,9.786-7.269,15.546c-0.528,3.868-0.196,7.787,0.238,11.65
		c0.654,5.746,1.14,11.683-0.35,17.345c5.862-3.831,13.696-5.026,20.124-1.873c6.115,3.251,13.481,3.597,20.091,1.77
		c3.947-1.107,7.918-3.05,10.384-6.451c1.425-1.836,1.448-4.288,2.588-6.269c2.569-4.858,6.904-8.53,11.557-11.314
		c4.555-2.607,9.88-4.69,15.21-3.69c2.032,0.28,3.606,1.85,5.62,2.158c5.124,0.248,10.193,1.654,14.752,4.003
		c6.932,3.461,12.286,9.642,15.289,16.733c1.313,3.27,2.536,6.694,2.625,10.254c-0.803-0.771-0.663-1.976-0.925-2.952
		c-1.532-7.133-5.073-13.944-10.515-18.872c-5.694-5.26-13.337-7.983-20.97-8.665c4.709,3.233,9.044,7.217,11.758,12.318
		c2.387,4.321,3.466,9.315,3.181,14.243c-0.238,8.45,1.799,17.802,8.478,23.539c4.148,3.373,9.487,4.606,14.369,6.531
		c3.704,1.448,7.717,2.943,10.216,6.218c1.046-2.943,3.13-5.372,5.662-7.152c3.882-3.648,6.615-8.385,8.595-13.29
		c2.882-7.156,3.34-15.546-0.042-22.614c-2.139-4.76-5.895-8.488-9.707-11.931c-8.334-7.409-17.966-13.888-28.948-16.485
		c-6.666-1.593-13.836-1.196-20.166,1.49c-0.565,0.397-1.616,0.332-1.742-0.435c-0.378-0.729,0.645-1.051,1.014-1.462
		c-0.271-1.789,1.383-2.99,1.883-4.564c0.177-0.691,0.603-1.201,1.275-1.523c-0.355-0.187-0.701-0.36-1.028-0.523
		c1.869-5.755,6.376-10.043,10.786-13.949c2.644-2.154,5.251-4.419,8.235-6.096c-0.196,0.495-0.509,0.897-0.939,1.196
		c-4.199,2.929-8.193,6.175-11.72,9.894c-2.256,2.326-4.171,4.998-5.456,7.983c0.14,0.364,0.29,0.738,0.458,1.121
		c1.64-2.677,3.877-4.924,6.063-7.156c7.124-7.012,15.551-12.599,24.426-17.144c0.892-0.659,1.42-1.691,2.032-2.593
		c1.462-2.466,2.835-5.232,2.593-8.175c-0.131-2.009-1.5-3.737-3.2-4.699c-2.999-1.77-6.545-2.023-9.908-2.527
		c-6.124-1.042-12.426-2.35-17.742-5.727c-2.125-1.172-3.046-3.592-5.054-4.882c-2.536-1.883-5.975-2.406-8.932-1.275
		c-4.639,1.546-9.403,5.059-9.903,10.286c-0.472,2.658,1.486,4.769,2.195,7.185c2.28,10.127,5.157,20.218,5.909,30.625
		c0.084,1.523,0.159,3.092-0.22,4.597c-0.444,0.743-1.602-0.318-1.822,0.663c0.07,0.453,0.215,1.355,0.285,1.808
		c-0.775-0.57-0.729-1.579-0.976-2.387c-2.41-10.333-6.045-20.745-13-28.944c-3.391-3.919-7.666-7.32-12.753-8.656
		c0.037-0.145,0.112-0.43,0.154-0.575c6.806,1.855,12.117,7.058,16.004,12.743c4.9,7.362,8.044,15.822,9.847,24.45
		c0.164,0.009,0.495,0.033,0.663,0.042c-0.028-8.946-2.177-17.695-4.181-26.356c-0.78-2.747-1.112-5.666-2.513-8.194
		c-0.738-1.383-0.962-2.957-1.663-4.34c-3.929-6.778-10.207-12.094-17.429-15.093C964.94,791.46,959.544,790.32,954.345,791.432
		 M596.797,791.871c-4.26,0.766-8.679,1.78-12.206,4.424c-2.214,2.041-3.139,5.078-4.125,7.843
		c-1.186,6.269-1.588,12.86-0.033,19.106c0.659,2.111,1.649,4.629,3.971,5.344c5.213,1.439,10.445,2.826,15.649,4.316
		c7.67,2.275,15.439,4.358,22.787,7.572c-2.943-3.933-6.157-7.834-7.792-12.538c-0.621-1.887-0.958-4.195,0.341-5.877
		c1.471-1.663,3.844-1.99,5.933-2.228c5.961-0.383,11.907,0.542,17.723,1.752c-4.858-2.728-8.123-7.376-11.286-11.818
		c-4.484-6.316-9.927-12.015-16.364-16.354C606.854,791.436,601.65,790.876,596.797,791.871 M633.854,814.499
		c1.448,1.939,3.223,3.732,5.391,4.867C638.101,817.152,635.994,815.648,633.854,814.499 M1040.228,827.298
		c-10.17,5.162-19.69,11.837-27.351,20.339c13.888-7.633,29.485-12.856,45.443-12.94c-0.052,0.14-0.15,0.416-0.196,0.556
		c-5.984-0.093-11.926,0.892-17.737,2.219c-9.833,2.28-19.241,6.171-28.028,11.099c-1.247,0.976-2.009,2.429-2.971,3.658
		c10.982-5.531,23.137-9.609,35.577-9.048c1.401,0.262,3.139-0.107,4.265,0.976c-1.742-0.014-3.443-0.495-5.176-0.472
		c-11.347-0.252-22.46,3.247-32.578,8.165c-1.093,0.556-2.373,0.883-3.153,1.897c13.897-3.499,28.663-2.368,42.304,1.747
		c1.425,0.519,3.064,0.729,4.209,1.826c-2.027-0.304-3.886-1.247-5.863-1.719c-12.178-3.41-25.169-4.611-37.614-1.957
		c-1.532,0.425-3.518,0.318-4.209,2.069c3.326-1.032,6.764-1.817,10.268-1.822c8.633-0.201,16.985,2.868,24.398,7.086
		c7.399,4.293,14.098,9.758,19.97,15.971c6.143-1.252,12.3-3.503,17.036-7.736c2.009-1.752,3.27-4.153,5.134-6.035
		c3.111-3.354,7.124-5.872,11.524-7.147c-6.278-5.853-11.529-12.683-16.509-19.634c-3.345-4.288-7.941-7.54-13.066-9.338
		C1057.67,824.182,1048.407,824.285,1040.228,827.298 M813.794,854.57c-0.883,1.668-1.28,3.522-1.808,5.321
		c-2.116,7.404-3.433,15.037-3.915,22.721c-1.481,22.922,4.97,45.77,15.742,65.861c8.759,16.415,20.194,31.284,32.975,44.751
		c6.101,6.516,12.809,12.416,19.068,18.779c12.687,12.715,24.501,26.286,35.479,40.5c0.906-4.489,2.046-8.922,3.242-13.337
		c-11.267-14.603-23.595-28.36-36.506-41.514c-6.624-6.918-13.738-13.355-20.04-20.577c-25.225-28.406-40.762-65.114-43.99-102.942
		C813.556,867.626,813.173,861.077,813.794,854.57 M982.373,868.471c-3.742,1.766-6.844,4.685-9.287,7.988
		c-1.243,1.546-1.584,3.531-2.107,5.391c-2.088,6.881-2.541,14.135-2.424,21.287c-0.028,4.601-0.056,9.483-2.294,13.645
		c2.882-2.667,5.456-5.676,8.535-8.142c2.831-2.28,5.839-4.741,9.534-5.344c2.364-0.336,4.176,1.808,4.891,3.84
		c1.756,4.989,1.462,10.384,1.289,15.588c7.128-11.505,16.009-21.787,24.347-32.405c1.056-1.518,0.803-3.485,0.346-5.162
		c-1.243-4.181-3.779-7.839-6.465-11.216c-2.219-2.607-4.414-5.367-7.432-7.077c-2.079-1.201-4.288-2.672-6.806-2.387
		C990.198,864.786,986.175,866.551,982.373,868.471 M733.541,894.855c2.611,22.763,8.894,45.069,18.302,65.954
		c2.013,4.363,4.008,8.782,6.675,12.795c-0.645-1.201-1.317-2.378-1.854-3.611c-10.333-21.941-15.883-46.008-17.191-70.177
		C737.922,897.821,735.484,896.513,733.541,894.855 M964.673,915.021c1.654-1.766,1.509-4.564,1.724-6.839
		C965.285,910.284,964.701,912.643,964.673,915.021 M760.419,917.889c3.321,31.985,14.22,62.899,29.962,90.839
		c1.999,3.419,3.868,6.937,6.152,10.183c12.071,13.472,24.249,26.846,36.525,40.127c5.61,5.961,11.043,12.108,16.849,17.873
		c-5.662-21.815-12.575-43.373-21.726-63.997c-7.703-17.004-16.639-33.498-27.341-48.815c-6.068-8.46-12.585-16.597-19.47-24.408
		C774.672,932.16,767.8,924.761,760.419,917.889 M1118.832,931.716c1.06,1.78,0.504,3.994,0.93,5.975
		c2.065,24.581-0.771,49.362-6.26,73.34c-9.03,38.8-25.417,75.568-45.55,109.823c-4.167,7.236-8.749,14.219-13.126,21.315
		c14.78-16.317,28.061-34.026,39.375-52.922c9.642-16.167,17.821-33.269,23.543-51.221c3.667-11.501,5.975-23.417,7.072-35.432
		c2.116-22.483,0.023-45.195-4.676-67.23C1119.766,934.169,1119.864,932.548,1118.832,931.716 M821.969,979.07
		c0.481,1.56,1.411,2.924,2.149,4.377c17.802,34.087,31.793,70.303,40.061,107.908c0.201,1.042,1.112,1.705,1.799,2.457
		c5.124,5.152,10.431,10.141,15.093,15.733c10.898,12.926,19.83,27.379,27.855,42.224c0.733,1.238,1.154,2.714,2.28,3.7
		c-2.107-15.378-3.644-30.849-4.185-46.363c-0.341-11.193-0.374-22.418,0.645-33.578c-4.298-5.643-8.782-11.136-13.383-16.532
		c-10.735-12.571-22.362-24.328-33.68-36.366c-12.295-12.972-24.599-25.982-35.624-40.071
		C824.001,981.368,823.249,979.967,821.969,979.07 M926.56,1055.997c-0.976,4.62-1.84,9.268-2.667,13.921
		c12.711,18.526,23.824,38.16,33.101,58.63c7.647,16.938,14.112,34.437,18.751,52.445c0.621,2.7,1.7,5.335,1.766,8.137
		c0.575,0.589,0.514,1.467,0.696,2.247c0.5,2.658,1,5.325,1.537,7.988c-0.168-2.387-0.757-4.713-1.201-7.044
		c-0.448-1.841-0.397-3.765-1.135-5.517c-5.536-26.729-12.683-53.234-23.072-78.52C946.82,1090,937.589,1072.403,926.56,1055.997
		 M920.609,1093.653c-0.831,8.698-1.476,17.424-1.588,26.169c-0.369,12.346,0.579,24.693,2.079,36.941
		c1.64,12.907,3.966,25.739,7.17,38.356c4.825,18.97,11.225,37.506,18.4,55.71c6.502,16.364,13.626,32.48,21.418,48.269
		c2.238,4.4,4.298,8.927,7.054,13.038c2.139-12.884,2.896-25.968,2.952-39.02c0.145-50.011-12.057-100.279-36.735-143.9
		C935.412,1116.837,928.471,1104.911,920.609,1093.653 M866.421,1102.393c2.252,12.57,3.882,25.262,4.569,38.02
		c0.434,11.127,1.042,22.301,2.929,33.283c6.937,11.889,15.341,23.081,25.818,32.097c7.782,6.671,15.869,13.066,22.651,20.806
		c2.653,2.733,4.769,5.951,7.465,8.642c-5.554-17.004-10.216-34.306-13.771-51.838c-0.831-3.237-0.864-6.722-2.49-9.702
		c-8.936-18.952-19.073-37.459-31.751-54.192C877.147,1113.427,872.148,1107.522,866.421,1102.393 M856.177,1104.668
		c-0.439,15.415,1.901,30.92,6.881,45.517c0.476,1.341,0.743,2.812,1.686,3.952c-0.448-4.26-1.07-8.502-1.49-12.762
		c-1.551-10.964-3.672-21.839-5.881-32.685C857.032,1107.326,857.051,1105.822,856.177,1104.668 M847.372,1129.183
		c-1.434,22.18,1.911,45.2,12.743,64.852c4.41,7.839,9.712,15.215,15.887,21.759c-1.434-5.624-2.639-11.305-3.816-16.98
		c-0.29-1.537-1.439-2.681-2.163-4.003c-9.983-16.069-17.368-33.862-20.801-52.506
		C848.315,1137.97,848.068,1133.541,847.372,1129.183 M755.491,1182.53c1.887,11.921,7.227,23.062,14.229,32.812
		c9.389,13.066,21.493,23.973,34.544,33.283c16.093,11.403,33.792,20.437,52.202,27.467c7.778,2.836,15.551,5.722,23.048,9.263
		c9.24,4.199,18.106,9.188,26.739,14.519c-0.392-0.855-0.593-1.906-1.481-2.373c-9.1-6.073-18.55-11.613-28.229-16.714
		c-8.292-4.512-16.966-8.25-25.356-12.566c-7.885-4.092-15.532-8.633-22.927-13.556
		C799.719,1235.535,774.905,1210.866,755.491,1182.53 M881.514,1211.361c1.121,4.339,2.303,8.665,3.522,12.981
		c0.21,1.121,1.266,1.724,2.027,2.481c10.249,9.053,21.605,16.84,33.657,23.287c-0.818-0.897-1.77-1.64-2.728-2.345
		C904.521,1237.044,892.296,1224.777,881.514,1211.361 M894.043,1252.782c1.079,3.844,2.607,7.549,3.924,11.319
		c3.088,8.179,6.049,16.443,9.637,24.408c13.089,11.393,26.968,21.932,39.291,34.194c11.996,11.898,22.946,24.828,32.989,38.408
		c10.576,14.36,20.222,29.411,28.766,45.064c0.668,1.084,0.99,2.513,2.2,3.172c-3.391-11.197-7.367-22.217-11.599-33.115
		c-7.381-18.723-15.939-37.048-26.585-54.15c-4.737-7.432-9.768-14.673-14.103-22.348c-1.551-2.354-2.443-5.199-4.629-7.077
		c-6.063-5.68-12.426-11.118-19.503-15.504c-3.816-2.424-8.039-4.064-12.024-6.157
		C912.322,1265.974,902.932,1259.677,894.043,1252.782 M914.195,1304.096c0.299,0.747,0.612,1.588,1.373,2.037
		c5.783,4.003,11.23,8.469,16.775,12.799C926.71,1313.504,920.487,1308.726,914.195,1304.096 M924.411,1326.285
		c7.203,15.07,15.023,29.868,23.744,44.125c4.101,6.811,8.871,13.173,13.566,19.577c1.056,1.476,2.803,2.186,4.246,3.228
		c19.022,12.767,35.81,28.794,49.787,46.933c2.079,2.546,3.751,5.405,5.919,7.885c-0.733-3.373-1.378-6.829-2.985-9.908
		c-2.588-5.517-5.129-11.052-7.703-16.569c-1.798-3.644-3.167-7.516-5.484-10.875c-16.588-26.038-35.946-50.777-60.26-70.065
		C938.603,1335.427,931.75,1330.438,924.411,1326.285 M1166.409,1341.612c-9.174,2.99-18.9,3.849-28.495,3.751
		c-5.288-0.019-10.552-0.654-15.836-0.388c-24.132,0.916-47.563,11.066-65.525,27.056c-3.153,2.938-6.353,5.937-8.759,9.53
		c5.62-5.227,11.702-9.978,18.204-14.075c15.453-9.814,33.386-16.466,51.856-16.452c5.479-0.089,10.903,0.794,16.312,1.56
		c10.441,1.201,21.451,1.13,31.275-3.05c7.231-3.06,13.724-8.899,15.981-16.597
		C1177.336,1337.165,1171.912,1339.771,1166.409,1341.612 M849.759,1379.09c-5.736,1.612-11.239,3.919-16.793,6.054
		c-9.38,3.625-18.774,7.226-28.205,10.711c-18.4,6.745-36.936,13.257-55.981,17.952c-11.753,2.789-23.805,5.138-35.937,4.354
		c-3.957-0.383-8.021-0.962-11.571-2.845c0.009,0.135,0.028,0.411,0.037,0.547c4.293,3.102,9.217,5.255,14.299,6.694
		c10.305,2.91,21.18,3.172,31.784,2.144c19.568-1.92,38.277-8.492,56.341-15.957c9.782-3.961,19.47-8.165,29.196-12.267
		c17.102-7.175,34.643-14.187,53.263-16.004c13.411-1.392,26.991,0.051,40.061,3.209c9.118,2.111,17.957,5.246,26.734,8.437
		c-2.906-1.887-6.133-3.195-9.249-4.681c-10.525-4.685-21.572-8.203-32.872-10.45C890.708,1372.952,869.537,1373.26,849.759,1379.09
		 M986.96,1422.51c0.836,1.761,2.303,3.102,3.461,4.653c7.413,9.048,14.859,18.106,23.203,26.328
		c2.401,2.326,4.821,4.713,7.754,6.381c-2.887-4.942-6.493-9.441-10.043-13.916c-6.414-7.853-13.594-15.088-21.455-21.488
		C988.974,1423.706,988.086,1422.893,986.96,1422.51z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M891.572,679.74c5.531-1.336,11.286-2.504,16.91-2.681
		c-6.526,0.504-12.898,2.177-19.152,4.045c-15.261,4.741-29.509,12.351-42.659,21.353c-23.534,16.219-43.49,37.146-60.611,59.919
		c-7.652,10.296-14.986,20.862-21.259,32.059c-14.743,26.188-23.917,55.692-25.272,85.78c-2.396-1.64-4.797-3.275-7.143-4.98
		c0.028-15.869,1.929-31.774,6.255-47.064c4.433-15.855,11.323-30.999,20.213-44.849c6.096-9.45,13.052-18.321,20.241-26.954
		c19.251-22.908,41.855-43.285,67.874-58.237C860.956,690.111,875.927,683.706,891.572,679.74z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1140.329,701.84c9.389-0.715,18.676-2.63,27.612-5.596
		c-1.906,3.433-5.979,4.625-9.403,5.961c-12.407,4.013-25.426,5.685-38.352,7.068c-20.512,1.612-40.043,9.735-57.172,20.862
		c-3.658,2.308-7.068,4.966-10.525,7.544c5.115-6.956,12.029-12.356,19.18-17.078c16.172-10.389,34.876-17.195,54.131-18.386
		C1130.641,701.91,1135.495,702.303,1140.329,701.84z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M598.6,702.835c1.294-0.93,2.387-2.154,3.859-2.812
		c-2.382,3.76-4.639,7.941-4.494,12.529c-0.131,4.013,2.158,7.726,5.199,10.183c2.616,2.046,5.101,4.456,6.451,7.549
		c1.168,2.541,1.345,5.764-0.285,8.151c-0.733,1.126-1.85,1.925-2.985,2.607c-7.231,4.354-13.692,10.151-18.27,17.284
		c-6.007,9.301-8.605,20.806-6.937,31.77c-0.051,1.084,1.018,1.28,1.859,1.392l0.271,0.224l-0.262,0.766
		c0.182,0.005,0.542,0.014,0.724,0.019c0.093,0.234,0.276,0.705,0.369,0.944c0.533-1.593,0.547-3.405,1.616-4.765
		c4.195-5.564,10.609-8.955,17.158-10.954c7.768-2.359,15.948-3.153,24.043-2.924c0.458,0.047,0.869-0.079,1.243-0.369
		c-3.06-0.565-6.161-0.07-9.235-0.079c-8.329,0.589-16.784,2.154-24.221,6.105c-3.078,1.672-5.975,3.774-8.231,6.474
		c-0.425,0.135-0.836,0.285-1.238,0.453c-0.388,1.065-0.547,2.859-1.7,3.237c-0.042-0.36-0.117-1.084-0.159-1.443
		c-0.14-0.079-0.425-0.248-0.565-0.332c-0.846-8.38,0.462-17.027,4.172-24.623c4.008-8.413,10.735-15.308,18.494-20.362
		c1.911-1.345,4.008-2.406,6.236-3.106c8.338-2.906,17.816-2.99,25.907,0.761c4.167,1.896,7.923,4.741,10.739,8.362
		c4.33,5.512,6.83,12.248,11.459,17.55c1.154,1.378,2.462,2.616,3.812,3.802c-5.643,0.322-11.361,2.569-15.079,6.942
		c-1.995,2.149-2.948,4.998-4.755,7.283c-4.008,5.162-9.913,8.792-16.247,10.3c-3.742,0.855-8.095,0.883-11.277-1.551
		c-4.872-4.171-11.678-4.956-17.854-4.685c-5.797,0.439-12.155,1.826-16.149,6.395c-1.509,1.486-1.593,4.111-3.7,4.994
		c0.29,0.21,0.575,0.43,0.888,0.663c-4.069,9.231-3.611,20.89,2.747,29.014c4.615,5.886,8.315,13.028,8.156,20.689
		c-0.042,3.49-1.201,6.89-3.032,9.843c-2.593,4.354-5.853,8.292-8.189,12.799c-1.014,1.934-1.714,4.045-1.794,6.246
		c-2.144-1.243-4.484-2.266-6.998-2.331c-3.555,0.028-6.97-1.14-10.221-2.462c-6.68-2.84-12.856-7.413-16.569-13.748
		c-3.022-5.124-4.013-11.281-3.205-17.139c1.159-7.974,3.289-15.836,6.573-23.202c2.826-6.255,6.601-12.197,11.781-16.761
		c3.592-3.195,7.909-5.592,12.552-6.848c0.845-0.308,1.887-0.336,2.485-1.102c0.028-1.056-0.995-1.331-1.826-1.13
		c-1.009-1.775-3.373-1.784-4.849-3.018c-0.313-0.019-0.944-0.056-1.257-0.075c0.117-0.28,0.35-0.841,0.467-1.121
		c-3.176-2.13-6.82-3.433-10.581-4.078c-5.307-0.972-10.716-1.144-16.097-1.102c-0.009,0.154-0.023,0.472-0.033,0.631
		c7.283-0.14,14.752,0.145,21.698,2.555c1.682,0.472,3.013,1.724,4.671,2.228c-0.224,0.229-0.444,0.472-0.654,0.724
		c-4.952-1.789-10.24-2.499-15.467-2.887c-7.96-0.649-15.962,0.023-23.842,1.149c-2.896-0.757-5.713-2.051-7.988-4.008
		c-1.733-1.481-2.99-3.812-2.392-6.129c0.916-3.756,3.947-6.414,6.633-8.974c3.672-3.686,7.217-7.577,9.842-12.094
		c1.191-2.163,2.331-4.498,2.27-7.021c-0.019-3.952,2.42-7.876,6.175-9.263c4.022-1.462,8.81-1.892,12.641,0.346
		c1.967,1.224,3.77,3.181,3.971,5.596c0.322,2.055,0.813,4.111,1.859,5.937c3.177,7.834,6.381,15.691,10.487,23.095
		c1.257,2.116,2.48,4.326,4.27,6.054c0.659,0.747,1.364-0.388,1.644-0.906c-2.779-3.097-4.681-6.844-6.563-10.52
		c-3.177-6.47-5.989-13.122-8.535-19.862c-0.369-2.065-0.846-4.101-1.542-6.077c-0.986-7.666,0.897-15.574,4.76-22.231
		c2.289-3.938,5.213-7.652,9.128-10.085c3.863-2.438,8.385-3.405,12.678-4.793C590.065,708.025,594.625,705.853,598.6,702.835
		 M582.703,738.412c-6.232,14.262-9.464,30.504-5.704,45.863c0.187-0.304,0.308-0.617,0.364-0.948
		c-1.154-5.265-1.766-10.707-1.327-16.088c0.617-9.871,3.2-19.578,7.208-28.598c0.313-0.92,1.107-1.738,1.028-2.742
		C583.329,736.366,583.194,737.576,582.703,738.412 M572.87,738.875c-3.317,5.358-4.204,11.851-3.989,18.05
		c0.448,9.716,3.447,19.162,7.539,27.925l0.715-0.154c0.192,0.364,0.402,0.733,0.617,1.107c0-0.378-0.005-1.135-0.005-1.513
		c-0.271,0.033-0.803,0.098-1.07,0.131c-2.859-6.914-5.498-13.981-6.619-21.409c-0.528-3.873-1.023-7.81-0.453-11.706
		c0.439-5.171,2.079-10.347,5.381-14.416C573.889,736.955,573.445,738.113,572.87,738.875 M593.429,738.884
		c-8.025,8.941-14.084,20.035-15.588,32.069c-0.589,3.849-0.248,7.74-0.023,11.608c0.182,0.005,0.547,0.023,0.729,0.033
		c-1.439-9.338,0.533-18.956,4.601-27.407c3.111-6.643,7.521-12.585,12.5-17.933C594.634,737.3,594.073,738.235,593.429,738.884
		 M601.211,756.009c-9.025,6.035-14.719,16.055-17.018,26.51c-0.304,1.85-1.079,3.784-0.668,5.648
		c0.061-0.028,0.187-0.089,0.248-0.121c1.168-8.464,4.027-16.878,9.338-23.665c5.321-6.867,13.122-11.772,21.638-13.523
		c0.902-0.248,2.004-0.117,2.691-0.888C611.6,750.347,606.065,752.847,601.211,756.009 M602.617,767.613
		c-6.745,4.671-12.954,10.534-16.565,18.013c-0.528,0.341-0.224,0.953,0.075,1.289c2.807-6.591,7.857-11.954,13.425-16.331
		c7.544-5.867,16.154-10.254,25.099-13.556c0.43-0.126,0.696-0.416,0.799-0.855C617.388,758.961,609.662,762.787,602.617,767.613
		 M555.432,762.554c0.327,1.383,1.144,2.569,1.803,3.816c2.565,4.634,5.232,9.245,8.488,13.439c1.215,1.392,2.34,3.106,4.181,3.704
		c-5.227-5.003-8.801-11.384-12.286-17.653C556.936,764.726,556.539,763.352,555.432,762.554 M541.549,770.159
		c-0.019,0.145-0.047,0.43-0.065,0.575c8.39,1.504,16.92,4.555,23.058,10.693c1.098,0.967,1.714,2.476,3.083,3.125
		c-0.972-2.037-2.709-3.569-4.33-5.082C557.189,774.255,549.355,771.546,541.549,770.159 M537.985,777.151
		c0.103,0.135,0.304,0.411,0.406,0.551c5.783,0.425,11.59,1.392,17.004,3.545c2.924,1.224,5.816,2.742,8.016,5.073
		c0.495,0.458,0.916,1.173,1.696,1.182c-2.308-3.284-5.937-5.353-9.586-6.825C549.939,778.492,543.95,777.455,537.985,777.151
		 M540.97,779.987c0.327,0.925,1.42,0.995,2.205,1.359c7.128,2.466,14.159,5.232,21.343,7.521c-0.598-0.874-1.658-1.06-2.565-1.411
		C554.942,785.009,548,782.369,540.97,779.987 M578.144,783.715c0.252,1.07,0.308,2.261,0.976,3.195
		c0.121-1.434-0.444-2.826-0.355-4.256C578.551,782.995,578.34,783.35,578.144,783.715 M570.684,798.822
		c-5.241,3.177-9.151,8.1-12.384,13.22c-3.971,6.456-7.077,13.449-9.31,20.689c0.649-0.145,0.822-0.752,1.004-1.299
		c2.088-6.25,4.765-12.328,8.133-17.999c3.513-5.788,7.829-11.449,13.902-14.729c1.275-0.789,2.896-0.958,4.027-1.971
		C574.089,796.804,572.398,797.976,570.684,798.822 M574.122,799.434c-5.54,2.976-9.604,8.072-12.515,13.547
		c-4.33,8.236-6.615,17.401-7.824,26.585c-0.201,0.518-0.07,1.046,0.388,1.378c1.023-8.343,2.906-16.644,6.325-24.352
		c2.864-6.39,6.965-12.613,13.136-16.219c1.369-0.892,3.064-1.215,4.284-2.326C576.547,798.163,575.313,798.808,574.122,799.434
		 M578.611,800.952c-3.971,3.83-7.614,8.147-9.763,13.285c-3.919,8.894-3.237,19.353,0.715,28.117
		c0.462,0.888,0.733,2.037,1.766,2.466c-0.439-1.257-1.121-2.401-1.626-3.62c-2.607-6.194-3.569-13.126-2.424-19.764
		c1.299-7.474,5.484-14.252,11.062-19.307C578.41,801.835,578.541,801.246,578.611,800.952z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M582.703,738.412c0.49-0.836,0.626-2.046,1.57-2.513
		c0.079,1.004-0.715,1.822-1.028,2.742c-4.008,9.02-6.591,18.727-7.208,28.598c-0.439,5.381,0.173,10.823,1.327,16.088
		c-0.056,0.332-0.178,0.645-0.364,0.948C573.239,768.916,576.472,752.674,582.703,738.412z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M572.87,738.875c0.575-0.761,1.018-1.92,2.116-1.985
		c-3.303,4.069-4.942,9.245-5.381,14.416c-0.57,3.896-0.075,7.834,0.453,11.706c1.121,7.427,3.76,14.495,6.619,21.409
		c0.266-0.033,0.799-0.098,1.07-0.131c0,0.378,0.005,1.135,0.005,1.513c-0.215-0.374-0.425-0.743-0.617-1.107l-0.715,0.154
		c-4.092-8.763-7.091-18.209-7.539-27.925C568.666,750.726,569.554,744.233,572.87,738.875z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M593.429,738.884c0.645-0.649,1.205-1.584,2.219-1.63
		c-4.98,5.349-9.389,11.291-12.5,17.933c-4.069,8.45-6.04,18.069-4.601,27.407c-0.182-0.009-0.547-0.028-0.729-0.033
		c-0.224-3.868-0.565-7.759,0.023-11.608C579.345,758.919,585.403,747.825,593.429,738.884z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M601.211,756.009c4.854-3.162,10.389-5.662,16.228-6.04
		c-0.687,0.771-1.789,0.64-2.691,0.888c-8.516,1.752-16.317,6.657-21.638,13.523c-5.311,6.787-8.17,15.201-9.338,23.665
		c-0.061,0.033-0.187,0.093-0.248,0.121c-0.411-1.864,0.364-3.798,0.668-5.648C586.492,772.064,592.186,762.044,601.211,756.009z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M602.617,767.613c7.044-4.826,14.771-8.651,22.833-11.44
		c-0.103,0.439-0.369,0.729-0.799,0.855c-8.946,3.303-17.555,7.689-25.099,13.556c-5.568,4.377-10.618,9.74-13.425,16.331
		c-0.299-0.336-0.603-0.948-0.075-1.289C589.664,778.146,595.872,772.284,602.617,767.613z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M555.432,762.554c1.107,0.799,1.504,2.172,2.186,3.307
		c3.485,6.269,7.058,12.65,12.286,17.653c-1.84-0.598-2.966-2.312-4.181-3.704c-3.256-4.195-5.923-8.805-8.488-13.439
		C556.577,765.123,555.759,763.936,555.432,762.554z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M541.549,770.159c7.806,1.387,15.64,4.097,21.745,9.31
		c1.621,1.513,3.359,3.046,4.33,5.082c-1.369-0.649-1.985-2.158-3.083-3.125c-6.138-6.138-14.668-9.188-23.058-10.693
		C541.502,770.588,541.53,770.303,541.549,770.159z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M537.985,777.151c5.965,0.304,11.954,1.341,17.536,3.527
		c3.648,1.471,7.278,3.541,9.586,6.825c-0.78-0.009-1.201-0.724-1.696-1.182c-2.2-2.331-5.092-3.849-8.016-5.073
		c-5.414-2.153-11.221-3.12-17.004-3.545C538.288,777.563,538.088,777.287,537.985,777.151z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M540.97,779.987c7.03,2.382,13.972,5.022,20.984,7.469
		c0.906,0.35,1.967,0.537,2.565,1.411c-7.185-2.289-14.215-5.054-21.343-7.521C542.39,780.982,541.297,780.912,540.97,779.987z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M578.144,783.715c0.196-0.364,0.406-0.719,0.621-1.06
		c-0.089,1.429,0.477,2.821,0.355,4.256C578.453,785.976,578.396,784.784,578.144,783.715z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M526.96,792.287c10.193-1.607,20.666-1.878,30.854-0.098
		c0.719,0.145,1.49,0.192,2.097,0.696c-14.271,1.789-28.341,6.255-40.552,13.939c-1.06,0.766-2.434,1.28-2.976,2.565
		c2.658-1.537,5.124-3.396,7.871-4.788c11.122-6.031,23.459-9.6,35.974-11.22c2.055-0.612,4.031,0.481,5.975,1.004
		c-11.973,2.069-24.085,5.727-33.75,13.351c-1.168,1.093-2.742,1.948-3.312,3.522c2.135-1.556,3.933-3.55,6.194-4.952
		c8.619-5.872,18.751-9.137,28.948-10.954c1.215-0.252,2.429-0.551,3.686-0.528c-1.229,1.051-2.878,1.355-4.279,2.107
		c-11.463,5.536-21.189,14.252-28.887,24.31c-0.846,1.285-2.135,2.396-2.378,3.98c2.102-2.312,3.7-5.05,5.82-7.362
		c8.198-9.665,18.452-17.812,30.34-22.418c0.336,0.154,1.009,0.458,1.345,0.612c-7.941,2.322-14.593,7.88-19.246,14.612
		c-6.998,10.174-10.683,22.31-12.342,34.456c-7.082,3.335-15.485,5.428-23.1,2.667c-5.344-1.691-11.211-1.658-16.508,0.206
		c1.205-5.811,1.588-11.758,1.808-17.676c0.187-4.391-0.355-8.862,0.752-13.169c1.901-8.292,7.68-15.257,14.682-19.867
		C519.36,795.094,523.013,793.193,526.96,792.287z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M954.345,791.432c5.199-1.112,10.595,0.028,15.406,2.111
		c7.222,2.999,13.5,8.315,17.429,15.093c0.701,1.383,0.925,2.957,1.663,4.34c1.401,2.527,1.733,5.447,2.513,8.194
		c2.004,8.661,4.153,17.41,4.181,26.356c-0.168-0.009-0.5-0.033-0.663-0.042c-1.803-8.628-4.947-17.088-9.847-24.45
		c-3.887-5.685-9.198-10.889-16.004-12.743c-0.042,0.145-0.117,0.43-0.154,0.575c5.087,1.336,9.361,4.737,12.753,8.656
		c6.956,8.198,10.59,18.611,13,28.944c0.248,0.808,0.201,1.817,0.976,2.387c-0.07-0.453-0.215-1.355-0.285-1.808
		c0.22-0.981,1.378,0.079,1.822-0.663c0.378-1.504,0.304-3.074,0.22-4.597c-0.752-10.408-3.63-20.498-5.909-30.625
		c-0.71-2.415-2.667-4.527-2.195-7.185c0.5-5.227,5.265-8.74,9.903-10.286c2.957-1.13,6.395-0.607,8.932,1.275
		c2.009,1.289,2.929,3.709,5.054,4.882c5.316,3.377,11.617,4.685,17.742,5.727c3.363,0.505,6.909,0.757,9.908,2.527
		c1.7,0.962,3.069,2.691,3.2,4.699c0.243,2.943-1.13,5.708-2.593,8.175c-0.612,0.902-1.14,1.934-2.032,2.593
		c-8.875,4.545-17.303,10.132-24.426,17.144c-2.186,2.233-4.424,4.48-6.063,7.156c-0.168-0.383-0.318-0.757-0.458-1.121
		c1.285-2.985,3.2-5.657,5.456-7.983c3.527-3.718,7.521-6.965,11.72-9.894c0.43-0.299,0.743-0.701,0.939-1.196
		c-2.985,1.677-5.592,3.943-8.235,6.096c-4.41,3.905-8.918,8.193-10.786,13.949c0.327,0.163,0.673,0.336,1.028,0.523
		c-0.673,0.322-1.098,0.832-1.275,1.523c-0.5,1.574-2.154,2.775-1.883,4.564c-0.369,0.411-1.392,0.733-1.014,1.462
		c0.126,0.766,1.177,0.832,1.742,0.435c6.33-2.686,13.5-3.083,20.166-1.49c10.982,2.597,20.615,9.076,28.948,16.485
		c3.812,3.443,7.568,7.17,9.707,11.931c3.382,7.068,2.924,15.457,0.042,22.614c-1.981,4.905-4.714,9.642-8.595,13.29
		c-2.532,1.78-4.615,4.209-5.662,7.152c-2.499-3.275-6.512-4.769-10.216-6.218c-4.882-1.925-10.221-3.158-14.369-6.531
		c-6.68-5.736-8.717-15.088-8.478-23.539c0.285-4.928-0.794-9.922-3.181-14.243c-2.714-5.101-7.049-9.086-11.758-12.318
		c7.633,0.682,15.275,3.405,20.97,8.665c5.442,4.928,8.983,11.739,10.515,18.872c0.262,0.976,0.121,2.182,0.925,2.952
		c-0.089-3.56-1.313-6.984-2.625-10.254c-3.004-7.091-8.357-13.271-15.289-16.733c-4.559-2.35-9.628-3.756-14.752-4.003
		c-2.013-0.308-3.588-1.878-5.62-2.158c-5.33-1-10.655,1.084-15.21,3.69c-4.653,2.784-8.988,6.456-11.557,11.314
		c-1.14,1.981-1.163,4.433-2.588,6.269c-2.466,3.401-6.437,5.344-10.384,6.451c-6.61,1.826-13.977,1.481-20.091-1.77
		c-6.428-3.153-14.262-1.957-20.124,1.873c1.49-5.662,1.004-11.599,0.35-17.345c-0.435-3.863-0.766-7.782-0.238-11.65
		c0.794-5.76,3.48-11.174,7.269-15.546c4.05-4.639,9.427-8.021,15.21-10.062c6.054-2.312,12.711-1.915,19.036-1.238
		c14.238,1.677,27.547,10.394,34.306,23.104c-5.961-6.138-12.944-11.59-21.185-14.257c-9.006-3.032-19.162-2.513-27.776,1.509
		c-0.705,0.364-1.635,0.612-1.798,1.513c9.231-4.984,20.573-5.755,30.41-2.125c8.362,3.069,15.64,8.749,21.329,15.523
		c-2.873-1.182-5.208-3.321-8.095-4.466c-9.544-4.167-20.302-4.185-30.471-2.99c-5.311,0.682-10.571,1.761-15.71,3.251
		c-0.103,0.164-0.308,0.49-0.411,0.649c10.996-3.018,22.488-4.854,33.895-3.63c5.713,0.71,11.491,2.182,16.368,5.344
		c-9.048-2.195-18.433,0.425-26.608,4.377c-4.513,2.177-8.796,4.826-12.851,7.768c-0.888,0.654-1.878,1.247-2.392,2.27
		c1.654-0.752,2.938-2.083,4.466-3.046c7.535-5.162,15.878-9.501,24.945-11.146c4.442-0.743,9.081-0.677,13.411,0.654
		c1.71,0.43,2.579,2.256,4.246,2.733c-0.229-0.252-0.691-0.747-0.92-0.995l1.004-0.117c-0.238-0.229-0.705-0.682-0.944-0.911
		c0.505-0.434,1.042-0.846,1.537-1.294c-3.746-7.853-9.964-14.444-17.363-18.975c-8.801-5.442-19.349-7.572-29.607-7.1
		c-2.401-0.009-4.975,0.715-7.217-0.444c-2.639-1.233-4.143-4.05-4.569-6.825c-0.537-3.125,0.047-6.288,0.925-9.287
		c1.084-3.994,0.565-8.553-2.097-11.828c-2.621-3.503-6.769-5.199-10.534-7.082c3.943,0.019,7.927,0.369,11.842-0.378
		C942.769,795.641,948.333,792.721,954.345,791.432 M960.357,815.284c1.145,1.14,2.691,1.719,4.041,2.593
		c12.645,7.708,23.847,18.55,29.784,32.312c-0.164,0.36-0.318,0.733-0.467,1.116c-3.471-8.17-9.997-14.757-17.405-19.494
		c-7.301-4.545-15.523-7.792-24.053-9.016c0.42,0.771,1.373,0.687,2.111,0.897c7.554,1.518,14.855,4.372,21.399,8.451
		c7.479,4.676,13.869,11.319,17.541,19.4c0.374,0.729,0.561,1.752,1.5,1.99c-0.243-0.575-0.551-1.158-0.836-1.672
		c0.145-0.378,0.313-0.743,0.495-1.093l0.341,1.168c0.579-0.472,0.028-1.145-0.107-1.658c-5.517-13.743-16.658-24.454-28.976-32.26
		C963.963,817.059,962.361,815.648,960.357,815.284 M1012.405,821.09c-5.115,6.054-9.385,13.309-10.039,21.362
		c-0.019,0.803-0.378,1.817,0.364,2.438c0.173-9.128,4.821-17.499,10.767-24.169c0.729-0.888,1.705-1.607,2.153-2.695
		C1014.283,818.694,1013.414,820.006,1012.405,821.09 M999.316,820.146c-0.037,2.742,0.607,5.442,0.831,8.18
		c0.416,5.61,1.271,11.407-0.304,16.924c1.565-0.864,1.046-3.237,1.383-4.76c0.215-5.76-0.402-11.515-1.107-17.219
		C999.914,822.206,1000.031,821.02,999.316,820.146 M1021.874,821.833c-4.372,3.634-8.427,7.722-11.557,12.491
		c-2.186,3.438-4.158,7.334-4.022,11.515c1.018-1.378,0.593-3.307,1.336-4.83c2.747-7.698,8.679-13.734,14.813-18.9
		C1022.304,822.038,1022.014,821.903,1021.874,821.833 M1019.421,828.485c-4.008,6.04-8.329,11.889-12.159,18.041
		c1.28-0.49,1.714-1.939,2.532-2.924c3.938-5.956,8.268-11.683,11.949-17.793C1020.589,826.303,1020.131,827.546,1019.421,828.485
		 M1008.589,858.377c-1.187,0.145-2.686-0.014-3.499,1.037c5.087-1.079,10.38-0.49,15.327,0.995
		c5.862,1.738,11.412,4.424,16.667,7.535c3.018,1.663,5.704,3.868,8.693,5.573c-0.49-1.074-1.579-1.612-2.476-2.261
		c-2.994-2.06-6.082-3.98-9.277-5.69C1026.214,861.427,1017.604,857.821,1008.589,858.377 M1002.913,860.068
		c0.916-0.252,2.051-0.28,2.658-1.149C1004.632,859.119,1003.352,859.021,1002.913,860.068 M1002.474,861.651
		c0.033,0.15,0.103,0.444,0.135,0.593c6.797-2.191,14.168-0.518,20.465,2.438c7.904,3.789,14.827,9.366,20.951,15.588
		c0.883,0.813,1.527,2.032,2.766,2.382c-1.261-1.971-3.107-3.457-4.713-5.12c-6.381-6.021-13.561-11.431-21.792-14.645
		C1014.666,860.698,1008.318,859.867,1002.474,861.651z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M596.797,791.871c4.853-0.995,10.057-0.434,14.598,1.542
		c6.437,4.34,11.879,10.039,16.364,16.354c3.162,4.442,6.428,9.09,11.286,11.818c-5.816-1.21-11.762-2.135-17.723-1.752
		c-2.088,0.238-4.461,0.565-5.933,2.228c-1.299,1.682-0.962,3.989-0.341,5.877c1.635,4.704,4.849,8.605,7.792,12.538
		c-7.348-3.214-15.116-5.297-22.787-7.572c-5.204-1.49-10.436-2.878-15.649-4.316c-2.322-0.715-3.312-3.233-3.971-5.344
		c-1.556-6.246-1.154-12.837,0.033-19.106c0.986-2.765,1.911-5.802,4.125-7.843C588.117,793.651,592.536,792.637,596.797,791.871z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M570.684,798.822c1.714-0.845,3.405-2.018,5.372-2.088
		c-1.13,1.014-2.751,1.182-4.027,1.971c-6.073,3.279-10.389,8.941-13.902,14.729c-3.368,5.671-6.045,11.748-8.133,17.999
		c-0.182,0.547-0.355,1.154-1.004,1.299c2.233-7.241,5.339-14.234,9.31-20.689C561.533,806.922,565.443,801.998,570.684,798.822z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M574.122,799.434c1.191-0.626,2.424-1.271,3.793-1.387
		c-1.219,1.112-2.915,1.434-4.284,2.326c-6.171,3.606-10.272,9.828-13.136,16.219c-3.419,7.708-5.302,16.009-6.325,24.352
		c-0.458-0.332-0.589-0.859-0.388-1.378c1.21-9.184,3.494-18.349,7.824-26.585C564.518,807.506,568.582,802.409,574.122,799.434z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M578.611,800.952c-0.07,0.294-0.201,0.883-0.271,1.177
		c-5.578,5.054-9.763,11.832-11.062,19.307c-1.144,6.638-0.182,13.57,2.424,19.764c0.504,1.219,1.186,2.364,1.626,3.62
		c-1.032-0.43-1.303-1.579-1.766-2.466c-3.952-8.763-4.634-19.223-0.715-28.117C570.997,809.099,574.641,804.782,578.611,800.952z"
        />
        <path
          fill="#8C6239"
          stroke="#8C6239"
          strokeWidth="0.0438"
          d="M633.854,814.499c2.139,1.149,4.246,2.653,5.391,4.867
		C637.078,818.231,635.303,816.437,633.854,814.499z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M960.357,815.284c2.004,0.364,3.606,1.775,5.367,2.733
		c12.318,7.806,23.459,18.517,28.976,32.26c0.135,0.514,0.687,1.187,0.107,1.658l-0.341-1.168c-0.182,0.35-0.35,0.715-0.495,1.093
		c0.285,0.514,0.593,1.098,0.836,1.672c-0.939-0.238-1.126-1.261-1.5-1.99c-3.672-8.081-10.062-14.724-17.541-19.4
		c-6.545-4.078-13.846-6.932-21.399-8.451c-0.738-0.21-1.691-0.126-2.111-0.897c8.53,1.224,16.751,4.47,24.053,9.016
		c7.409,4.737,13.935,11.323,17.405,19.494c0.15-0.383,0.304-0.757,0.467-1.116c-5.937-13.762-17.139-24.604-29.784-32.312
		C963.048,817.003,961.502,816.423,960.357,815.284z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1012.405,821.09c1.009-1.084,1.878-2.396,3.247-3.064
		c-0.448,1.088-1.425,1.808-2.153,2.695c-5.947,6.671-10.595,15.042-10.767,24.169c-0.743-0.621-0.383-1.635-0.364-2.438
		C1003.02,834.399,1007.29,827.144,1012.405,821.09z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M999.316,820.146c0.715,0.874,0.598,2.06,0.803,3.125
		c0.705,5.704,1.322,11.459,1.107,17.219c-0.336,1.523,0.182,3.896-1.383,4.76c1.574-5.517,0.719-11.314,0.304-16.924
		C999.923,825.589,999.279,822.888,999.316,820.146z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1021.874,821.833c0.14,0.07,0.43,0.206,0.57,0.276
		c-6.133,5.167-12.066,11.202-14.813,18.9c-0.743,1.523-0.318,3.452-1.336,4.83c-0.135-4.181,1.836-8.077,4.022-11.515
		C1013.447,829.555,1017.501,825.467,1021.874,821.833z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1040.228,827.298c8.179-3.013,17.443-3.116,25.678-0.238
		c5.125,1.798,9.721,5.05,13.066,9.338c4.98,6.951,10.23,13.78,16.509,19.634c-4.4,1.275-8.413,3.793-11.524,7.147
		c-1.864,1.883-3.125,4.284-5.134,6.035c-4.737,4.232-10.894,6.484-17.036,7.736c-5.872-6.213-12.571-11.678-19.97-15.971
		c-7.413-4.218-15.766-7.287-24.398-7.086c-3.503,0.005-6.942,0.789-10.268,1.822c0.691-1.752,2.677-1.644,4.209-2.069
		c12.444-2.653,25.435-1.453,37.614,1.957c1.976,0.472,3.835,1.415,5.863,1.719c-1.145-1.098-2.784-1.308-4.209-1.826
		c-13.64-4.115-28.406-5.246-42.304-1.747c0.78-1.014,2.06-1.341,3.153-1.897c10.118-4.919,21.231-8.418,32.578-8.165
		c1.733-0.023,3.433,0.458,5.176,0.472c-1.126-1.084-2.864-0.715-4.265-0.976c-12.44-0.561-24.595,3.517-35.577,9.048
		c0.962-1.229,1.724-2.681,2.971-3.658c8.787-4.928,18.195-8.819,28.028-11.099c5.811-1.327,11.753-2.312,17.737-2.219
		c0.047-0.14,0.145-0.416,0.196-0.556c-15.957,0.084-31.555,5.307-45.443,12.94C1020.538,839.135,1030.058,832.46,1040.228,827.298z
		"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1019.421,828.485c0.71-0.939,1.168-2.181,2.322-2.677
		c-3.681,6.11-8.011,11.837-11.949,17.793c-0.817,0.986-1.252,2.434-2.532,2.924
		C1011.092,840.373,1015.413,834.525,1019.421,828.485z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M813.794,854.57c-0.621,6.507-0.238,13.056,0.248,19.564
		c3.228,37.828,18.765,74.536,43.99,102.942c6.302,7.222,13.416,13.659,20.04,20.577c12.912,13.154,25.239,26.911,36.506,41.514
		c-1.196,4.414-2.336,8.848-3.242,13.337c-10.978-14.215-22.791-27.785-35.479-40.5c-6.26-6.362-12.968-12.262-19.068-18.779
		c-12.781-13.467-24.216-28.336-32.975-44.751c-10.772-20.091-17.223-42.939-15.742-65.861c0.481-7.684,1.798-15.317,3.915-22.721
		C812.514,858.092,812.911,856.237,813.794,854.57z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1008.589,858.377c9.016-0.556,17.625,3.05,25.435,7.189
		c3.195,1.71,6.283,3.63,9.277,5.69c0.897,0.649,1.985,1.187,2.476,2.261c-2.99-1.705-5.676-3.91-8.693-5.573
		c-5.255-3.111-10.805-5.797-16.667-7.535c-4.947-1.485-10.24-2.074-15.327-0.995
		C1005.903,858.363,1007.402,858.521,1008.589,858.377z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1002.913,860.068c0.439-1.046,1.719-0.948,2.658-1.149
		C1004.964,859.787,1003.828,859.815,1002.913,860.068z"
        />
        <path
          stroke="#000000"
          strokeWidth="0.0438"
          d="M1002.474,861.651c5.844-1.784,12.192-0.953,17.812,1.238
		c8.231,3.214,15.411,8.623,21.792,14.645c1.607,1.663,3.452,3.148,4.713,5.12c-1.238-0.35-1.883-1.57-2.766-2.382
		c-6.124-6.222-13.047-11.8-20.951-15.588c-6.297-2.957-13.668-4.629-20.465-2.438
		C1002.577,862.095,1002.507,861.801,1002.474,861.651z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M982.373,868.471c3.802-1.92,7.824-3.686,12.127-3.994
		c2.518-0.285,4.727,1.187,6.806,2.387c3.018,1.71,5.213,4.47,7.432,7.077c2.686,3.377,5.223,7.035,6.465,11.216
		c0.458,1.677,0.71,3.644-0.346,5.162c-8.338,10.618-17.219,20.9-24.347,32.405c0.173-5.204,0.467-10.599-1.289-15.588
		c-0.715-2.032-2.527-4.176-4.891-3.84c-3.695,0.603-6.703,3.064-9.534,5.344c-3.078,2.466-5.652,5.475-8.535,8.142
		c2.238-4.162,2.266-9.044,2.294-13.645c-0.117-7.152,0.336-14.406,2.424-21.287c0.523-1.859,0.864-3.845,2.107-5.391
		C975.53,873.157,978.631,870.237,982.373,868.471z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M733.541,894.855c1.943,1.658,4.382,2.966,5.933,4.961
		c1.308,24.169,6.858,48.236,17.191,70.177c0.537,1.233,1.21,2.41,1.854,3.611c-2.667-4.013-4.662-8.432-6.675-12.795
		C742.435,939.924,736.152,917.618,733.541,894.855z"
        />
        <path
          fill="#8C6239"
          stroke="#8C6239"
          strokeWidth="0.0438"
          d="M964.673,915.021c0.028-2.378,0.612-4.737,1.724-6.839
		C966.182,910.457,966.327,913.255,964.673,915.021z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1118.832,931.716c1.032,0.831,0.934,2.452,1.308,3.648
		c4.699,22.035,6.792,44.747,4.676,67.23c-1.098,12.015-3.405,23.931-7.072,35.432c-5.722,17.952-13.902,35.054-23.543,51.221
		c-11.314,18.896-24.595,36.605-39.375,52.922c4.377-7.096,8.96-14.079,13.126-21.315c20.133-34.255,36.521-71.023,45.55-109.823
		c5.489-23.978,8.324-48.759,6.26-73.34C1119.336,935.71,1119.892,933.496,1118.832,931.716z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M926.56,1055.997c11.029,16.406,20.26,34.003,27.776,52.286
		c10.389,25.286,17.536,51.791,23.072,78.52c0.738,1.752,0.687,3.676,1.135,5.517c0.444,2.331,1.032,4.657,1.201,7.044
		c-0.537-2.663-1.037-5.33-1.537-7.988c-0.182-0.78-0.121-1.658-0.696-2.247c-0.065-2.803-1.144-5.437-1.766-8.137
		c-4.639-18.008-11.104-35.507-18.751-52.445c-9.277-20.47-20.39-40.104-33.101-58.63
		C924.72,1065.265,925.584,1060.617,926.56,1055.997z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M847.372,1129.183c0.696,4.358,0.944,8.787,1.85,13.122
		c3.433,18.643,10.819,36.436,20.801,52.506c0.724,1.322,1.873,2.466,2.163,4.003c1.177,5.676,2.382,11.356,3.816,16.98
		c-6.175-6.544-11.477-13.921-15.887-21.759C849.282,1174.383,845.938,1151.362,847.372,1129.183z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M755.491,1182.53c19.414,28.336,44.228,53.006,72.77,72.135
		c7.395,4.924,15.042,9.464,22.927,13.556c8.39,4.316,17.064,8.053,25.356,12.566c9.679,5.101,19.129,10.641,28.229,16.714
		c0.888,0.467,1.089,1.518,1.481,2.373c-8.633-5.33-17.499-10.319-26.739-14.519c-7.498-3.541-15.271-6.428-23.048-9.263
		c-18.41-7.03-36.109-16.065-52.202-27.467c-13.052-9.31-25.155-20.218-34.544-33.283
		C762.718,1205.592,757.378,1194.451,755.491,1182.53z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M881.514,1211.361c10.781,13.416,23.006,25.683,36.478,36.404
		c0.958,0.705,1.911,1.448,2.728,2.345c-12.052-6.447-23.408-14.234-33.657-23.287c-0.761-0.757-1.817-1.359-2.027-2.481
		C883.817,1220.026,882.635,1215.701,881.514,1211.361z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M914.195,1304.096c6.292,4.629,12.515,9.408,18.148,14.836
		c-5.545-4.33-10.992-8.796-16.775-12.799C914.807,1305.684,914.494,1304.844,914.195,1304.096z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M1166.409,1341.612c5.503-1.841,10.926-4.447,15.014-8.665
		c-2.256,7.698-8.749,13.537-15.981,16.597c-9.824,4.181-20.834,4.251-31.275,3.05c-5.409-0.766-10.833-1.649-16.312-1.56
		c-18.47-0.014-36.404,6.638-51.856,16.452c-6.502,4.097-12.584,8.848-18.204,14.075c2.406-3.592,5.606-6.591,8.759-9.53
		c17.961-15.99,41.393-26.141,65.525-27.056c5.283-0.266,10.548,0.369,15.836,0.388
		C1147.509,1345.461,1157.235,1344.601,1166.409,1341.612z"
        />
        <path
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="0.0438"
          d="M849.759,1379.09c19.778-5.83,40.949-6.138,61.106-2.102
		c11.3,2.247,22.348,5.764,32.872,10.45c3.116,1.485,6.344,2.793,9.249,4.681c-8.777-3.191-17.616-6.325-26.734-8.437
		c-13.07-3.158-26.65-4.601-40.061-3.209c-18.62,1.817-36.161,8.829-53.263,16.004c-9.726,4.101-19.414,8.305-29.196,12.267
		c-18.064,7.465-36.773,14.037-56.341,15.957c-10.604,1.028-21.479,0.766-31.784-2.144c-5.082-1.439-10.006-3.592-14.299-6.694
		c-0.009-0.135-0.028-0.411-0.037-0.547c3.55,1.883,7.614,2.462,11.571,2.845c12.131,0.785,24.183-1.565,35.937-4.354
		c19.045-4.695,37.581-11.207,55.981-17.952c9.431-3.485,18.825-7.086,28.205-10.711
		C838.519,1383.009,844.022,1380.701,849.759,1379.09z"
        />
      </g>
    </svg>
  );
}

export default Frame;
